export async function setupPeriodicJWTFetch(intervalSeconds: number, tenant: string) {
    const jwtGenerateURL = `${import.meta.env.VITE_TOKXCHANGE_URL}/api/v2/generate`;
    async function fetchToken() {
        const res = await fetch(jwtGenerateURL, {
            method: "POST",
            headers: {
                "x-tenant-id": tenant,
            },
            credentials: "include",
        });
        if (res.ok) {
            const token = await res.json();
            localStorage.setItem("token", token);
        }
        return res;
    }

    const timeout = setInterval(fetchToken, intervalSeconds * 1000);
    await fetchToken();
    return () => clearInterval(timeout);
}
