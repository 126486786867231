import { defineMessages } from "react-intl";

const messages = defineMessages({
    close: {
        defaultMessage: "close",
        id: "close",
    },
    "default.verification.email.title": {
        id: "default.verification.email.title",
        defaultMessage: " ",
    },
    "register.verification.email.title": {
        id: "register.verification.email.title",
        defaultMessage: "A verification-email has been sent to  ",
    },
    "recovery.verification.email.title": {
        id: "recovery.verification.email.title",
        defaultMessage: "A recovery-email has been sent to ",
    },
    "settings.password.verification.email.title": {
        id: "settings.verification.email.title",
        defaultMessage: "Password updated for account ",
    },
    "default.verification.email.description": {
        id: "default.verification.email.description",
        defaultMessage: " ",
    },
    "register.verification.email.description": {
        id: "register.verification.email.description",
        defaultMessage:
            "Please check your inbox and follow the instructions provided in your email to verify your account. After verification, click below to login.",
    },
    "recovery.verification.email.description": {
        id: "recovery.verification.email.description",
        defaultMessage:
            "Please check your inbox and follow the instructions provided in your email to recover your password.",
    },
    "settings.password.verification.email.description": {
        id: "settings.verification.email.description",
        defaultMessage: "Please click below to continue",
    },
    check: {
        id: "verification.email.check",
        defaultMessage: "Continue",
    },
});

export default messages;
