import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";

import { router } from "./router";
import { worker } from "./mocks/browser";
import { blacklist } from "./constants";
import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";

Sentry.init({
    dsn: import.meta.env.VITE_AUTH_SENTRY_DNS,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    beforeSend(event) {
        const userId = event.tags?.["userId"];
        if (userId) {
            // ignore checkly accounts
            if (blacklist.includes(userId.toString())) {
                return null;
            }
        }
        const timeMs = event.tags?.["timeMs"];
        if (timeMs) {
            const v = parseInt(timeMs.toString());
            const bucket500ms = Math.floor(v / 500) * 500;
            event.tags = {
                ...event.tags,
                timeMs: bucket500ms,
            };
        }
        return event;
    },
});

const useMockServer = import.meta.env.VITE_USE_MOCK_SERVICE_WORKER === "true";
if (useMockServer) {
    worker.start();
}

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <FeatureToggleContextProvider userEmail={localStorage.getItem("lastEmail") ?? ""} tenant="">
            <RouterProvider router={router} />
        </FeatureToggleContextProvider>
    </React.StrictMode>
);
