import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import EmailSVG from "../../assets/email.svg";

import messages from "./messages";

const EmailConfirmationModal = () => {
    const { formatMessage } = useIntl();
    return (
        <Dialog open>
            <img src={EmailSVG} alt="Email confirmation" width="100%" />
            <DialogContent>
                <Stack p={3} spacing={2}>
                    <Typography variant="h3">{formatMessage(messages.title)}</Typography>
                    <Typography padding={{ vertical: "s" }}>{formatMessage(messages.description)}</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default EmailConfirmationModal;
