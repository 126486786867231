import { LoadingButton } from "@mui/lab";
import { Alert, CircularProgress, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { FormEvent } from "react";
import { useIntl } from "react-intl";

import { useTotpRegistration } from "./hooks";
import { LookupSecretModal } from "./LookupSecretModal";
import messages from "./messages";
import { LookupSecret } from "./interfaces";

export function TOPTSection() {
    const { formatMessage } = useIntl();
    const {
        loading,
        qrNodeAttributes,
        secretKeyNodeAttributes,
        lookupSecretNodeAttributes,
        errors,
        onVerify,
        removeMFA,
        generateRecoveryCodes,
        confirmRecoveryCodes,
    } = useTotpRegistration();

    function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const code = formData.get("code") as string;
        onVerify(code);
    }

    // Need to override the style as context is just an object
    const context = lookupSecretNodeAttributes?.text.context as { secrets: LookupSecret[] } | undefined;

    return (
        <Stack spacing={2} py={2}>
            <Typography variant="h4">{formatMessage(messages.title)}</Typography>
            {loading.general && (
                <Stack alignItems="center" p={3}>
                    <CircularProgress />
                </Stack>
            )}
            {!qrNodeAttributes && !loading.general && (
                <Stack spacing={2}>
                    <Typography>{formatMessage(messages.removeMFADescription)}</Typography>
                    <LoadingButton variant="outlined" onClick={generateRecoveryCodes} loading={loading.generate}>
                        {formatMessage(messages.generateCodesButton)}
                    </LoadingButton>
                    <LoadingButton onClick={removeMFA} color="error" variant="outlined" loading={loading.remove}>
                        {formatMessage(messages.removeMFAButton)}
                    </LoadingButton>
                </Stack>
            )}
            {qrNodeAttributes && !loading.general && (
                <Stack spacing={2}>
                    <img {...qrNodeAttributes} alt="TOTP QR Code" />
                    <Stack>
                        <Typography variant="overline">{formatMessage(messages.secretKeyDescription)}</Typography>
                        <Typography>{secretKeyNodeAttributes?.text.text}</Typography>
                    </Stack>
                    <Stack component="form" onSubmit={onSubmit}>
                        <TextField
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LoadingButton
                                            loading={loading.verify}
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                        >
                                            {formatMessage(messages.verify)}
                                        </LoadingButton>
                                    </InputAdornment>
                                ),
                            }}
                            name="code"
                            label={formatMessage(messages.enterCode)}
                        />
                    </Stack>
                </Stack>
            )}
            <LookupSecretModal
                open={!!lookupSecretNodeAttributes}
                secrets={context?.secrets || []}
                onConfirm={confirmRecoveryCodes}
                loading={loading.confirm}
            />
            {errors?.map((err) => (
                <Alert key={err.message} severity="error">
                    {err.message}
                </Alert>
            ))}
        </Stack>
    );
}
