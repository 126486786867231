import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "auth.verification.title",
        defaultMessage: "Perfect, you are all set!",
    },
    description: {
        id: "auth.verification.description",
        defaultMessage: "Your email is now verified and you are ready to get started!",
    },
    button: {
        id: "auth.verification.button",
        defaultMessage: "Continue to login",
    },
});

export default messages;
