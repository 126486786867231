import { defineMessages } from "react-intl";

const messages = defineMessages({
    refreshSessionTitle: {
        defaultMessage: "Refresh your session",
        id: "Refresh your session",
    },
    refreshSessionAlertBody: {
        defaultMessage: "To keep your account safe and secure, please renew your session before accessing this page.",
        id: "To keep your account safe and secure, please renew your session before accessing this page.",
    },
});

export default messages;
