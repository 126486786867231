import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "verification.email.title",
        defaultMessage: "Email verification successful",
    },
    description: {
        id: "verification.email.description",
        defaultMessage: "Please login now or click the invitation url again",
    },
});

export default messages;
