import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Enable/Manage multi-factor authentication with Authencticator App",
        id: "TOPTSection.title",
    },
    secretKeyDescription: {
        defaultMessage: "If you can not scan the QR code, use the secret below:",
        id: "TOPTSection.secretKeyDescription",
    },
    enterCode: {
        defaultMessage: "Enter 6 digit code",
        id: "TOPTSection.enterCode",
    },
    verify: {
        defaultMessage: "Verify",
        id: "TOPTSection.verify",
    },

    generateCodesButton: {
        defaultMessage: "Generate recovery codes",
        id: "TOPTSection.generateCodesButton",
    },

    removeMFADescription: {
        defaultMessage:
            "Multi-factor authentication is enabled for this account. You can generate one time recovery codes in case you loose access to your authenticator app.",
        id: "TOPTSection.disableMFADescription",
    },

    removeMFAButton: {
        defaultMessage: "Remove MFA",
        id: "TOPTSection.removeMFAButton",
    },
});

export default messages;
