import { defineMessages } from "react-intl";

const messages = defineMessages({
    back: {
        defaultMessage: "Back to login",
        id: "link.back"
    },
    genericError: {
        defaultMessage: "Something went wrong. Please try again later.",
        id: "link.genericError"
    },
    btnLink: {
        defaultMessage: "Link account",
        id: "link.btnLink"
    },
    subtitle: {
        defaultMessage: "This workspace requires users to log in with a {provider} account. You can link your existing account to your {provider} account to continue using this workspace.",
        id: "link.subtitle"
    },
    title: {
        defaultMessage: "Link account to {provider}?",
        id: "link.title"
    },
});

export default messages;
