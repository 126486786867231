import { useMutation } from "@apollo/client";
import { identify, track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Alert, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { FormEvent, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { INDUSTRY_KEYS } from "./constants";
import messages from "./messages";
import { createCustomerMutation } from "./queries";
import { useSession } from "@/hooks/useSession";

function RegisterCompanyPage() {
    const { formatMessage } = useIntl();
    const nameRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const sess = useSession();

    useEffect(() => {
        const currentTarget = nameRef?.current;
        if (currentTarget) {
            setTimeout(() => currentTarget.focus(), 200);
        }
    }, [nameRef]);

    const [createCustomer, { error, loading }] = useMutation<{ createCustomer: { result: { identifier: string } } }>(
        createCustomerMutation
    );

    const setUserIdentity = (tenant: string) => {
        const identity = sess.data?.session?.identity;
        if (identity && identity.traits) {
            identify({
                email: identity.traits.email,
                id: identity.id,
                tenantName: tenant,
                type: "authenticted",
            });
        }
    };

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const name = formData.get("name") as string;
        const industry = formData.get("industry") as string;
        createCustomer({ variables: { input: { name, industry, subscription: "self-served" } } }).then(
            async ({ errors }) => {
                if (errors) {
                    Sentry.captureException(errors, {
                        user: { id: sess.data?.session?.identity.id },
                    });
                    return;
                }

                setUserIdentity(name);
                track("User created company", { companyName: name });
                localStorage.setItem("v2", "true");
                navigate({ pathname: "/configuring" });
            }
        );
    }

    return (
        <Stack onSubmit={handleSubmit} component="form" spacing={3} maxWidth={400}>
            <Typography variant="h6">{formatMessage(messages.header)}</Typography>
            <Typography>{formatMessage(messages.description)}</Typography>
            <TextField
                required
                inputRef={nameRef}
                name="name"
                label={formatMessage(messages.companyNameLabel)}
            ></TextField>
            <FormControl>
                <InputLabel id="industry-select-label">{formatMessage(messages.companyIndustryLabel)}</InputLabel>
                <Select
                    name="industry"
                    required
                    labelId="industry-select-label"
                    label={formatMessage(messages.companyIndustryLabel)}
                >
                    {INDUSTRY_KEYS.map((key) => (
                        <MenuItem key={key} value={key}>
                            {formatMessage(messages[`industry${key}` as keyof typeof messages])}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <LoadingButton loading={loading} type="submit" variant="contained">
                <span>{formatMessage(messages.submitButton)}</span>
            </LoadingButton>
            {error && <Alert severity="error">{formatMessage(messages.error)}</Alert>}
        </Stack>
    );
}

export default RegisterCompanyPage;
