import { Stack } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import Layout from "@/components/Layout";
import LoginForm from "@/components/LoginForm";

import messages from "./messages";

const RefreshPage: React.FC = () => {
    const { formatMessage } = useIntl();

    const [searchParams] = useSearchParams();
    const redirectTo = searchParams.get("redirect_url") || "/settings/profile";
    const email = searchParams.get("email") ?? undefined;

    return (
        <Layout>
            <Stack gap={4} alignItems="center">
                <LoginForm
                    initialEmail={email}
                    redirectTo={redirectTo}
                    title={formatMessage(messages.refreshSessionTitle)}
                    refresh
                />
            </Stack>
        </Layout>
    );
};

export default RefreshPage;
