import { Aal2RequiredCode } from "@/components/LoginForm/constants";
import { Configuration, FrontendApi, Session } from "@ory/kratos-client";
import { AxiosError } from "axios";
import React, { useEffect, useMemo } from "react";
import { getCurrentDomain } from "@/utils/getCurrentHostname";

const kratosPublicUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_KRATOS_PUBLIC_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_KRATOS_PUBLIC_URL;
const configuration = new Configuration({
    basePath: kratosPublicUrl || "",
    baseOptions: {
        withCredentials: true,
        timeout: 5000,
    },
});

export const oryClient = new FrontendApi(configuration);

const cache = {
    inFlight: false,
    session: null as Session | null,
    ts: 0,
};

export async function getSession(): Promise<Session | null> {
    const { session, ts } = cache;
    const timeout = 1000; // 1 second
    if (session && Date.now() - ts < timeout) {
        return session;
    }
    if (cache.inFlight) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(getSession());
            }, 100);
        });
    }
    cache.inFlight = true;
    try {
        const { data } = await oryClient.toSession();
        cache.session = data;
        cache.ts = Date.now();
        cache.inFlight = false;
        return data;
    } catch (err) {
        cache.inFlight = false;
        return null;
    }
}

type IdentityTraits = {
    name: {
        first: string;
        last: string;
    };
    email: string;
    tenant?: string;
};

export async function getRequireAal2() {
    try {
        await oryClient.toSession();
        return false;
    } catch (err) {
        const e = err as AxiosError;
        if (e.response?.data.error?.id === Aal2RequiredCode) {
            return true;
        }
        return false;
    }
}

type SessionValid = {
    data: {
        userId: string;
        session: Session;
        tenant: string | null;
        traits: IdentityTraits;
    }
    loading: boolean;
    update: () => Promise<void>;
};
type SessionInvalid = {
    data: null;
    loading: boolean;
    update: () => Promise<void>;
};
export function useSession(): SessionValid | SessionInvalid {
    const [s, setSession] = React.useState<Session | null>(null);
    const [loading, setLoading] = React.useState(true);
    async function update() {
        setLoading(true);
        const session = await getSession();
        setSession(session);
        setLoading(false);
    }
    useEffect(() => {
        update();
    }, []);
    const traits = useMemo<null | IdentityTraits>(() => {
        if (!s) {
            return null;
        }
        return s.identity?.traits;
    }, [s]);
    const tenant = useMemo(() => {
        if (!s?.identity) {
            return null;
        }
        const md = s.identity.metadata_public;
        if (!md) {
            return null;
        }
        const tenant = md.tenant as string | undefined;
        if (!tenant) {
            return null;
        }
        return tenant;
    }, [s]);
    const userId = useMemo(() => {
        if (!s?.identity) {
            return null;
        }
        return s.identity.id;
    }, [s]);
    const valid = useMemo(() => {
        if (!s) return false;
        return !!s.active;
    }, [s]);

    return {
        data: valid ? {
            userId: userId!,
            session: s!,
            tenant,
            traits: traits!,
        } : null,
        loading,
        update,
    };
}


type LogoutError = "session_not_found" | "generic_error";

export async function logout(): Promise<null | { error: { code: LogoutError; details?: string } }> {
    const s = await getSession();
    if (!s) return { error: { code: "session_not_found" } };
    try {
        const { data } = await oryClient.createBrowserLogoutFlow({ cookie: s.id });
        const { status } = await oryClient.updateLogoutFlow({ token: data.logout_token });
        if (status === 204 || status === 401) {
            window.location.replace("/");
        }
        return null;
    } catch (err) {
        return { error: { code: "generic_error", details: JSON.stringify(err) } };
    }
}
