import { Stack } from "@mui/material";

import IgniteLogo from "@/assets/images/logo-long.svg";
import IgniteLogoTextOnly from "@/assets/images/logo-text-only.svg";
import { useNewAuthDesign } from "@/utils/useNewIgnite";

type Props = {
    children: JSX.Element;
    hideLogo?: boolean;
};

const Layout = ({ children, hideLogo }: Props): JSX.Element => {
    const newDesign = useNewAuthDesign();
    return (
        <Stack minHeight="100vh" direction="row" justifyContent="center" alignItems="center" px={2}>
            <Stack pb={12} pt={3}>
                {children}
            </Stack>
            {!hideLogo && !newDesign && (
                <Stack bottom={20} position="absolute" direction="row" justifyContent="center" sx={{ zIndex: -100 }}>
                    <a href="https://igniteprocurement.com/" rel="noreferrer" target="_blank">
                        <img width="300px" src={IgniteLogo} alt="Ignite Procurement" />
                    </a>
                </Stack>
            )}
            {newDesign && (
                <Stack bottom="100px" position="absolute" direction="row" justifyContent="center" sx={{ zIndex: -100 }}>
                    <a href="https://igniteprocurement.com/" rel="noreferrer" target="_blank">
                        <img src={IgniteLogoTextOnly} alt="Ignite Procurement" />
                    </a>
                </Stack>
            )}
        </Stack>
    );
};
export default Layout;
