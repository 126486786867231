// this page gets rendered if there's any error related to a ui flow
// see https://www.ory.sh/docs/kratos/self-service/flows/user-facing-errors
import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useState } from "react";

import {
    KratosErrorGeneric,
    KratosResponse,
    GetRegistrationFlowResponse,
    GetRecoveryFlowResponse,
    RecoveryFlowOK,
    GetUserFlowErrorsResponse,
} from "@/types";
import { getCurrentDomain } from "@/utils/getCurrentHostname";

import messages from "./messages";

export type FlowType = "error" | "registration" | "recovery";

// represents any value in messages
// https://stackoverflow.com/a/60148768
type Message = (typeof messages)[keyof typeof messages];

interface Issue {
    id: string;
    code: string;
    message: string | Message;
    reason?: string;
    debug?: string;
    reportToSentry?: boolean;
}

// holds a list of expected errors and whether they should be reported to sentry or not
// the debug is just for convenience of the developer, it's not used
// see ory source code, text/message_recovery.go
const expectedErrors: Record<number, { report: boolean; debug?: string }> = {
    4060004: {
        report: false,
        debug: "already used recovery flow",
    },
    4060006: {
        report: false,
        debug: "already used recovery flow",
    },
    4060001: {
        report: false,
        debug: "already accepted, can't use again",
    },
    4060002: {
        report: true,
        debug: "flow has failed, must be retried",
    },
    4060005: {
        report: false,
        debug: "recovery flow has expired",
    },
};

function collectIssues(r: KratosResponse<unknown, KratosErrorGeneric>): Issue {
    const { error } = r.data;
    const code = error.code.toString();
    let report = true;
    if (expectedErrors[error.code]?.report == false) {
        report = false;
    }

    return {
        id: code,
        reportToSentry: report,
        code,
        message: error.message,
        reason: error.reason,
    };
}

const kratosPublicUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_KRATOS_PUBLIC_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_KRATOS_PUBLIC_URL;
async function getSelfServiceErrorError(id: string): Promise<Issue> {
    const d = await fetch(kratosPublicUrl + "/self-service/errors?error=" + id, {credentials: "include"});
    const res = { status: d.status, data: await d.json() } as GetUserFlowErrorsResponse;
    if (res.status === 200) {
        const { error } = res.data;
        return {
            id: res.data.id,
            code: error.code.toString(),
            message: error.message,
            reason: error.reason,
            debug: error.debug,
        };
    } else {
        const { error } = res.data;
        return {
            id: error.code.toString(),
            code: error.code.toString(),
            message: error.message,
            reason: error.reason,
        };
    }
}

async function getSelfServiceRegistrationError(id: string): Promise<Issue> {
    const d = await fetch(kratosPublicUrl + "/self-service/registration/flows?id=" + id, {credentials: "include"});
    const res = { status: d.status, data: await d.json() } as GetRegistrationFlowResponse;

    if (res.status === 200) {
        const allMessages = res.data.ui.nodes.flatMap((n) => n.messages);
        if (allMessages.length === 0) {
            return Promise.reject("no messages found");
        }
        return {
            id: res.data.id,
            code: "registration_flow_error",
            message: allMessages.map((m) => m.text).join(", "),
            reason: "registration_flow_error",
        };
    } else {
        return collectIssues(res);
    }
}

async function getSelfServiceRecoveryError(id: string): Promise<Issue> {
    const d = await fetch(kratosPublicUrl + "/self-service/recovery/flows?id=" + id, {credentials: "include"});
    const res = { status: d.status, data: await d.json() } as GetRecoveryFlowResponse;

    if (res.status === 200) {
        const data = res.data as RecoveryFlowOK;

        const issues = (data.ui.messages || []).map((m) => {
            const messageKey = m.id as keyof typeof messages;
            return {
                id: m.id.toString(),
                code: m.id.toString(),
                message: messages[messageKey] || m.text,
                reason: "recovery_flow_error",
            };
        });
        if (issues.length > 0) {
            return issues[0];
        }
        return {
            id: "unknown",
            code: "internal",
            message: "unable to parse errors",
            reason: "unknown",
        };
    } else {
        return collectIssues(res);
    }
}

export function useErrorData(flowType: FlowType, id: string | null) {
    const [data, setData] = useState<Issue | null>(null);
    const [error, setError] = useState<string | null>(null); // in case we can't fetch the error details

    const handlers: Record<typeof flowType, (id: string) => Promise<Issue>> = useMemo(
        () => ({
            error: getSelfServiceErrorError,
            registration: getSelfServiceRegistrationError,
            recovery: getSelfServiceRecoveryError,
        }),
        []
    );

    useEffect(() => {
        if (!id) return;
        const fn = handlers[flowType];
        fn(id)
            .then(setData)
            .catch((err) => {
                setError(err);
                Sentry.captureException(err);
                return err;
            });
    }, [id, flowType, handlers]);
    return { data, error };
}
