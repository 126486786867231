import { ArrowLeft } from "@ignite-analytics/icons";
import * as Sentry from "@sentry/react";
import { Link as RouterLink } from "react-router-dom";
import { Alert, Button, Card, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Logo from "@/assets/main-logo.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { OTPInput } from "./OTPInput";
import { LoadingButton } from "@mui/lab";
import { resendEmail, submitRecoveryFlow } from "@/hooks/useRecoveryFlow";
import { useSearchParams } from "react-router-dom";
import { track } from "@ignite-analytics/track";

export const RecoverySubmit = () => {
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const canSubmit = useMemo(() => code.length === 6, [code]);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [resending, setResending] = useState(false);
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();

    function resetMessages() {
        setErrorMessage("");
        setInfoMessage("");
    }

    useEffect(() => {
        track("account recovery: page opened");
    }, []);

    function getStateParams() {
        const flow = searchParams.get("flow") ?? "";
        const email = sessionStorage.getItem("email") ?? "";
        const csrf = sessionStorage.getItem("csrf-token") ?? "";
        return { email, flow, csrf };
    }

    async function onResendEmail() {
        setResending(true);
        const { flow, csrf, email } = getStateParams();
        try {
            await resendEmail(flow, csrf, email, "code");
            setInfoMessage(formatMessage({ defaultMessage: "A new email has been sent to {email}" }, { email }));
        } finally {
            setResending(false);
        }
    }
    async function onSubmit() {
        resetMessages();
        try {
            setLoading(true);
            const { flow, csrf } = getStateParams();
            const res = await submitRecoveryFlow(flow, csrf, code, "code");
            if (res.error !== undefined) {
                Sentry.captureMessage("failed to submit recovery flow", {
                    tags: { method: "code" },
                    extra: { error: res.error },
                });
                setErrorMessage(res.error);
                return;
            } else {
                window.location.href = "/settings/authentication";
            }
        } finally {
            setLoading(false);
        }
    }
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [didFocus, setDidFocus] = useState(false);

    return (
        <Card sx={{ py: 4, px: 3, width: "444px" }}>
            <Stack
                gap={4.5}
                alignItems="center"
                onKeyDown={(e) => {
                    if (e.key === "Enter" && canSubmit) {
                        onSubmit();
                    }
                }}
            >
                <Stack sx={{ cursor: "pointer", marginLeft: "2px" }} py={0.5} alignItems="center">
                    <Link>
                        <img width={27} alt="Ignite Procurement Logo" src={Logo} />
                    </Link>
                </Stack>

                <Stack gap={4.5} alignItems="center">
                    <Stack gap={1} alignItems="center">
                        <Typography variant="textXl" fontWeight="500">
                            <FormattedMessage defaultMessage="Verify your identity" />
                        </Typography>
                        <Typography variant="textSm" color="textSecondary">
                            <FormattedMessage defaultMessage="Enter the 6-digit code you received in your email" />
                        </Typography>
                    </Stack>
                    <OTPInput
                        onChange={(code) => {
                            setCode(code);
                            if (code.length === 6 && !didFocus) {
                                setDidFocus(true);
                                setTimeout(() => {
                                    // button is disabled so we add a small delay before we focus
                                    buttonRef.current?.focus();
                                }, 50);
                            }
                        }}
                    />
                </Stack>

                <Stack gap={3} alignItems="center" width="100%">
                    <LoadingButton
                        ref={buttonRef}
                        loading={loading}
                        disabled={!canSubmit}
                        fullWidth
                        variant="contained"
                        onClick={onSubmit}
                        size="medium"
                    >
                        <FormattedMessage defaultMessage="Submit" />
                    </LoadingButton>

                    {/*  CTA */}
                    <Typography variant="textSm" color="textSecondary">
                        <FormattedMessage
                            defaultMessage="Not in Inbox or spam folder? {resendEmail}"
                            values={{
                                resendEmail: resending ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Link onClick={onResendEmail}>
                                        <FormattedMessage defaultMessage="Resend email" />
                                    </Link>
                                ),
                            }}
                        />
                    </Typography>

                    {/* back to login */}
                    <Button component={RouterLink} to="/login" startIcon={<ArrowLeft />} size="small" color="ghostGray">
                        <FormattedMessage defaultMessage="Back to login" />
                    </Button>
                </Stack>
                {errorMessage && (
                    <Stack width="100%">
                        <Alert severity="error">{errorMessage}</Alert>
                    </Stack>
                )}
                {infoMessage && (
                    <Stack width="100%">
                        <Alert severity="info">{infoMessage}</Alert>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
};
