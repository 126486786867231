import * as Sentry from "@sentry/react";
import React from "react";
import { oryClient } from "@/hooks/useSession";
import { SettingsBrowserLocationChangeRequired } from "@/types";

export type RecoveryMethod = "code" | "link";

type InitFlowResponseSuccess = { error: undefined; data: { flowId: string; csrf: string } };
type InitFlowResponseError = { error: "INVALID_EMAIL" | "EMAIL_NOT_SENT" | "INVALID_CODE" | "UNKNOWN" };
type InitFlowResponse = InitFlowResponseSuccess | InitFlowResponseError;

export async function initRecoveryFlowV2(
    email: string,
    method: RecoveryMethod,
    returnTo?: string
): Promise<InitFlowResponse> {
    const { data } = await oryClient.createBrowserRecoveryFlow({ returnTo });
    const csrfNode = data.ui.nodes.find((e) => e.group === "default");
    if (!csrfNode) {
        Sentry.captureMessage("failed to initialize recovery flow - no csrfNode found");
        return Promise.reject();
    }
    const attributes = csrfNode.attributes as unknown as { value: string };
    const flow = data.id;
    const csrf = attributes.value;
    // TODO: handle if this crashes
    const res = await oryClient.updateRecoveryFlow({
        flow,
        updateRecoveryFlowBody: { csrf_token: csrf, email, method },
    });
    if (res.status == 200) {
        const goodCodes = [1060002, 1060003]; // 2 is for link, 3 is for code
        const emailSent = res.data.ui.messages?.find((e) => goodCodes.includes(e.id)) !== undefined;

        if (emailSent) {
            return { error: undefined, data: { flowId: flow, csrf } };
        }
        return { error: "EMAIL_NOT_SENT" };
    } else if (res.status == 400) {
        const invalidEmail = res.data.ui.messages?.find((e) => e.id === 4000001) !== undefined;
        if (invalidEmail) {
            return { error: "INVALID_EMAIL" };
        }
        return { error: "UNKNOWN" };
    }
    return { error: "UNKNOWN" };
}

type SubmitRecoveryFlowError = { error: "INVALID_CODE" | "UNKNOWN"; data: undefined };
type SubmitRecoveryFlowSuccess = { error: undefined; data: { redirectTo?: string } };
type SubmitRecoveryFlowResponse = SubmitRecoveryFlowError | SubmitRecoveryFlowSuccess;
export async function submitRecoveryFlow(
    flow: string,
    csrf: string,
    code: string,
    method: RecoveryMethod
): Promise<SubmitRecoveryFlowResponse> {
    try {
        const res = await oryClient.updateRecoveryFlow({
            flow,
            updateRecoveryFlowBody: { csrf_token: csrf, method, code },
        });
        if (res.status === 200) {
            if (res.data.ui.messages !== undefined && res.data.ui.messages.length > 0) {
                const isInvalidCode = res.data.ui.messages.find((e) => e.id === 4060006) !== undefined;
                if (isInvalidCode) {
                    return { error: "INVALID_CODE", data: undefined };
                }
                Sentry.captureMessage("failed to submit recovery flow", {
                    tags: { method },
                    extra: { error: "UNKNOWN", messages: res.data.ui.messages },
                });
                return { error: "UNKNOWN", data: undefined };
            }
            return { error: undefined, data: {} };
        }
        Sentry.captureMessage("failed to submit recovery flow", {
            tags: { method },
            extra: { error: "UNKNOWN", status: res.status },
        });
    } catch (e) {
        const err = e as SettingsBrowserLocationChangeRequired;
        if (err.response.status === 422) {
            const link = err.response.data.redirect_browser_to;
            // get query param, extract flow
            const url = new URL(link);
            const flow = url.searchParams.get("flow");
            const nextURL = `${import.meta.env.VITE_IGNITE_PROCUREMENT_KRATOS_PUBLIC_URL}/ui/settings?flow=${flow}`;
            return { error: undefined, data: { redirectTo: nextURL } };
        }
    }
    Sentry.captureMessage("failed to submit recovery flow", {
        tags: { method },
        extra: { error: "UNKNOWN" },
    });
    return { error: "UNKNOWN", data: undefined };
}

export async function resendEmail(flow: string, csrf: string, email: string, method: RecoveryMethod) {
    await oryClient.updateRecoveryFlow({
        flow,
        updateRecoveryFlowBody: { csrf_token: csrf, method, email },
    });
}

async function initFlow(returnTo?: string): Promise<{ csrfToken: string; flow: string }> {
    let csrfToken = "",
        flow = "";
    const { data } = await oryClient.createBrowserRecoveryFlow({ returnTo });
    const csrfNode = data.ui.nodes.find((e) => e.group === "default");
    if (!csrfNode) {
        Sentry.captureMessage("failed to initialize recovery flow - no csrfNode found");
        return Promise.reject();
    }
    const attributes = csrfNode.attributes as unknown as { value: string };
    csrfToken = attributes.value;
    flow = data.id;
    return { csrfToken, flow };
}

export function useRecoveryFlow(returnTo?: string) {
    const [loading, setLoading] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    function send(email: string) {
        setLoading(true);
        return initFlow(returnTo)
            .then(({ csrfToken, flow }) => {
                return oryClient.updateRecoveryFlow({
                    flow,
                    updateRecoveryFlowBody: {
                        csrf_token: csrfToken,
                        email,
                        method: "link",
                    },
                });
            })
            .catch((e) => {
                setFailed(true);
                return Promise.reject(e);
            })
            .finally(() => setLoading(false));
    }

    return { send, failed, loading };
}
