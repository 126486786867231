import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import messages from "./messages";
import { LookupSecret } from "../interfaces";

interface Props {
    open: boolean;
    secrets: LookupSecret[];
    loading: boolean;
    onConfirm: () => void;
}

export function LookupSecretModal({ open, secrets, loading, onConfirm }: Props) {
    const { formatMessage } = useIntl();
    return (
        <Dialog open={open}>
            <DialogTitle>{formatMessage(messages.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText>{formatMessage(messages.description)}</DialogContentText>
                <Grid py={2} container spacing={1}>
                    {secrets.map((secret) => (
                        <Grid xs={4} key={secret.id} p={1} textAlign="center">
                            <Typography>{secret.text}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={loading} onClick={onConfirm} variant="contained">
                    {formatMessage(messages.confirmCodes)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
