import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import Arrow from "@/assets/images/arrow.svg";

import messages from "./messages";

interface Props {
    email?: string;
    open: boolean;
}
export const VerifyEmailDialog = ({ email, open }: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Dialog open={open}>
            <DialogContent>
                <Stack direction="row" justifyContent="center" py={3}>
                    <img width="250px" src={Arrow} alt="email verification sent" />
                </Stack>
                <Stack p={3} spacing={2}>
                    <Typography variant="h4">{formatMessage(messages.title)}</Typography>
                    <Typography variant="subtitle2">{formatMessage(messages.subTitle, { email })}</Typography>
                    <Typography variant="body1">{formatMessage(messages.description)}</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
