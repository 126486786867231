import { useFeatureToggle } from "@ignite-analytics/feature-toggle";

const featureKey = "auth-use-new-ignite";

export function useNewAuthDesign() {
    const prev = localStorage.getItem(featureKey) === "true";
    const value = useFeatureToggle(featureKey, prev);
    if (value !== prev) {
        localStorage.setItem(featureKey, "true");
    }

    return value;
}
