import {
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemText,
    Stack,
    Switch,
    Typography,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    ListItemIcon,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import messages from "../messages";
import { useSnackMessageContext } from "@/contexts/SnackMessageContext";

interface Provider {
    enabled: null | boolean;
    name: string;
    link: () => Promise<boolean>;
    unlink: () => Promise<boolean>;
    loading: boolean;
    logo?: string;
}
interface Props {
    providers: Provider[];
    beforeSubmit: () => boolean;
    disabled: boolean;
}

interface ToggleProviderDialogProps {
    open: boolean;
    content: {
        title: string;
        description?: string;
    };
    description?: string;
    onAccept: () => void;
    onDecline: () => void;
}

const ToggleProviderDialog = ({ open, content, onAccept, onDecline }: ToggleProviderDialogProps) => {
    return (
        <Dialog onClose={onDecline} open={open}>
            <DialogTitle>{content.title}</DialogTitle>
            {content.description && (
                <DialogContent>
                    <DialogContentText>{content.description}</DialogContentText>
                </DialogContent>
            )}

            <DialogActions>
                <Button color="error" variant="outlined" onClick={onDecline}>
                    Cancel
                </Button>
                <Button onClick={onAccept}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
};

const SocialSignInProviders: React.FC<Props> = ({ beforeSubmit, providers, disabled }) => {
    const { formatMessage } = useIntl();
    const [dialogOpen, setDialog] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [loading, setLoading] = useState(false); // link / unlink loading

    const { onMessage } = useSnackMessageContext();

    useEffect(() => {
        const ls = localStorage.getItem("sso-link-init");
        if (ls !== null) {
            setSelectedProvider(providers[0]);
            setDialog(true);
            localStorage.removeItem("sso-link-init");
        }
    }, [providers]);

    function applyChange() {
        setDialog(false);
        if (!selectedProvider) {
            // should never happen
            return;
        }
        const provider = selectedProvider;
        if (provider.enabled) {
            setLoading(true);
            provider
                .unlink()
                .then(() => {
                    onMessage(formatMessage(messages.unlinkSuccess, { name: provider.name }));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(true);
            provider.link().finally(() => {
                setLoading(false);
            });
        }
    }
    function dialogContentForProvider(p: Provider) {
        if (p.enabled) {
            return {
                title: formatMessage(messages.dialogUnlinkTitle, { name: p.name }),
                description: formatMessage(messages.dialogUnlinkBody, { name: p.name }),
            };
        } else {
            return {
                title: formatMessage(messages.dialogLinkTitle, { name: p.name }),
                description: formatMessage(messages.dialogLinkBody, { name: p.name }),
            };
        }
    }

    return (
        <Stack rowGap={2}>
            <Typography variant="h4">{formatMessage(messages.title)}</Typography>
            <Typography variant="body1">{formatMessage(messages.body)}</Typography>
            <ToggleProviderDialog
                open={dialogOpen}
                content={selectedProvider ? dialogContentForProvider(selectedProvider) : { title: "", description: "" }}
                onAccept={applyChange}
                onDecline={() => setDialog(false)}
            />
            <Divider />
            <List>
                {providers.map((provider, i) => (
                    <ListItem
                        sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.1)" } }}
                        key={`provider-${i}`}
                        secondaryAction={
                            provider.loading || loading ? (
                                <CircularProgress />
                            ) : (
                                <Tooltip title={formatMessage(messages.tooltipText)} open={tooltipOpen}>
                                    {provider.enabled == null ? (
                                        <CircularProgress />
                                    ) : (
                                        <Switch
                                            checked={provider.enabled}
                                            disabled={disabled}
                                            onChange={() => {
                                                setTooltipOpen(false);
                                                if (beforeSubmit()) {
                                                    setSelectedProvider(provider);
                                                    setDialog(true);
                                                }
                                            }}
                                        />
                                    )}
                                </Tooltip>
                            )
                        }
                    >
                        {provider.logo && (
                            <ListItemIcon>
                                <img width={24} src={provider.logo} />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={provider.name} />
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
};

export default SocialSignInProviders;
