import { track } from "@ignite-analytics/track";
import { Button, Card, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import IgniteLogo from "@/assets/images/logo-long.svg";

import messages from "./messages";

const VerifyPage = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const login = () => {
        navigate({ pathname: "/" });
        track("E-mail verified");
    };

    return (
        <Card sx={{ boxShadow: 4, px: 9, py: 6, pt: 8 }}>
            <Stack direction="row" justifyContent="center" pb={3}>
                <img width="168px" src={IgniteLogo} alt="email verified" />
            </Stack>
            <Stack p={3} spacing={3} alignItems="left" textAlign="center" maxWidth={400}>
                <Typography variant="h4">{formatMessage(messages.title)}</Typography>
                <Typography variant="body1">{formatMessage(messages.description)}</Typography>
                <Button onClick={() => login()}>{formatMessage(messages.button)}</Button>
            </Stack>
        </Card>
    );
};
export default VerifyPage;
