import { CircularProgress, Stack } from "@mui/material";
import { useIntl } from "react-intl";
import React, { useEffect } from "react";
import { identify } from "@ignite-analytics/track";
import * as Sentry from "@sentry/react";
import { ErrorContent } from "./Error";
import { Header } from "./Header";
import { OnboardingWithOidc } from "./Oidc";
import { RegularSignUp } from "./Regular";
import { Layout } from "./Layout";

import { OnboardingParameters } from "@/types";
import messages from "./messages";

type OnboardingParameterError = {
    data: null;
    error: { code: "not_found" | "already_completed" | "expired" | "internal_server_error"; detail?: string };
};

let called = false;
async function getOnboardingParamsFromHttpRequest(
    token: string
): Promise<OnboardingParameters | OnboardingParameterError> {
    const uri = `${import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL}/api/v1/accept-invite?token=${token}`;
    const res = await fetch(uri, {
        credentials: "include",
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (res.status == 404) {
        return {
            data: null,
            error: { code: "not_found" },
        };
    }
    if (res.status !== 200) {
        return {
            data: null,
            error: { code: "internal_server_error", detail: "some parameters are missing" },
        };
    }
    const data = (await res.json()) as OnboardingParameters["data"];
    return {
        data,
        error: null,
    };
}

function useOnboardingParams() {
    const [data, setData] = React.useState<OnboardingParameters["data"] | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<OnboardingParameterError["error"] | null>(null);

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get("token") || "";
        // StrictMode will call this twice, so we need to guard against that
        // probaly a better way to do this
        if (called) return;
        called = true;
        getOnboardingParamsFromHttpRequest(token).then((params) => {
            setData(params.data);
            setLoading(false);
            setError(params.error);
        });
    }, []);
    return { data, loading, error };
}

export const OnboardingPage: React.FC = () => {
    const { loading, data, error } = useOnboardingParams();
    const { formatMessage: fm } = useIntl();

    useEffect(() => {
        if (data) {
            const { user, workspace } = data;
            identify({
                email: user.email,
                id: user.id,
                type: "authenticted",
                tenantName: workspace.id,
            });
            Sentry.setUser({ id: user.id });
            Sentry.setTag("app", "auth-frontend");
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            Sentry.captureMessage("onboarding: error", { tags: { error: error.code } });
        }
    }, [error]);

    if (loading) {
        return (
            <Layout>
                <Stack sx={{ minHeight: "40vh", alignItems: "center", justifyContent: "center", p: 4 }}>
                    <CircularProgress />
                </Stack>
            </Layout>
        );
    }
    if (error) {
        const title = fm(messages[error.code]);
        let subtitle: string;
        const { code } = error;
        let redirectToApp = false;
        if (code == "not_found") {
            subtitle = fm(messages.not_found_subtitle);
        } else if (code == "expired") {
            subtitle = fm(messages.expired_subtitle);
        } else if (code == "already_completed") {
            subtitle = fm(messages.already_completed_subtitle);
            redirectToApp = true;
        } else {
            subtitle = fm(messages.internal_server_error_subtitle);
        }
        return <ErrorContent title={title} subtitle={subtitle} redirectToApp={redirectToApp} />;
    }

    if (!data) {
        // should never happen, so it's more a type assertion...
        return;
    }

    if (data.sso != null) {
        return (
            <Layout>
                <Header invitedBy={data.invitedBy} workspace={data.workspace} />
                <Stack maxWidth={550} sx={{ mb: 4, mt: 12, mx: "auto" }} alignItems="stretch">
                    <OnboardingWithOidc provider={data.sso} email={data.user.email} />
                </Stack>
            </Layout>
        );
    }

    return <RegularSignUp {...data} />;
};
