import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useSearchParams } from "react-router-dom";

const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <Container elevation={6} component={Paper} sx={{ p: 4, minHeight: "30vh", width: "600px" }}>
            {children}
        </Container>
    );
};

export const NoWorkspacesPage = () => {
    const [search] = useSearchParams();
    const email = search.get("email");
    const { formatMessage } = useIntl();
    return (
        <Layout>
            <Stack pb={4} gap={2}>
                <Typography variant="h4">{formatMessage(messages.noWorkspaces)}</Typography>
                <Typography>{formatMessage(messages.yourMail, { email })}</Typography>
            </Stack>
            <Stack gap={2}>
                <Typography variant="h5">{"You can try one of the following:"}</Typography>
                <Typography variant="body1">{formatMessage(messages.askAdmin)}</Typography>
                <Typography variant="body1">{formatMessage(messages.useIntercom)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" pt={4}>
                <Button href="/login" target="_blank" variant="outlined">
                    {formatMessage(messages.backToLogin)}
                </Button>
            </Stack>
        </Layout>
    );
};
