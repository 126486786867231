import { updateName, updatePassword } from "@/hooks/settings";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { VisibilityTextField } from "@/utils/passwordfield";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Collapse, Stack, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Header } from "./Header";
import { Layout } from "./Layout";
import messages from "./messages";

type SetNameProps = {
    active: boolean;
    onSubmit: (firstName: string, lastName: string) => Promise<void>;
};

export const SetName = ({ active, onSubmit }: SetNameProps) => {
    const [loading, setLoading] = React.useState(false);
    const { formatMessage: fm } = useIntl();
    const ref = React.useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (active) {
            ref.current?.focus();
        }
    }, [active]);
    const [first, setFirst] = React.useState("");
    const [last, setLast] = React.useState("");
    const disabled = useMemo(() => {
        if (first.length == 0) {
            return true;
        }
        if (last.length == 0) {
            return true;
        }
        return false;
    }, [first, last]);
    return (
        <Stack
            component="form"
            onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                onSubmit(first, last).then(() => setLoading(false));
            }}
        >
            <Stack mb={4}>
                <Typography variant="h4"> {fm(messages.headerName)} </Typography>
                <Typography color="text.secondary" variant="body2">
                    {fm(messages.optional)}
                </Typography>
            </Stack>
            <TextField
                inputRef={ref}
                id="firstName"
                label="First name"
                variant="standard"
                fullWidth
                autoFocus
                sx={{ mb: 2 }}
                onChange={(e) => setFirst(e.target.value)}
            />
            <TextField
                label="Last name"
                variant="standard"
                fullWidth
                sx={{ mb: 2 }}
                onChange={(e) => setLast(e.target.value)}
            />
            <Stack alignSelf="end" direction="row" gap={2}>
                <Button variant="text" color="inherit" sx={{ mb: 2 }}>
                    {fm(messages.later)}
                </Button>
                <LoadingButton
                    type="submit"
                    disabled={disabled}
                    loading={loading}
                    variant={active ? "contained" : "outlined"}
                    sx={{ mb: 2, alignSelf: "end" }}
                >
                    {fm(messages.next)}
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

type SetPasswordProps = {
    active: boolean;
    onSubmit: (pw: string) => Promise<void>;
    email: string;
};

export const SetPassword = ({ active, onSubmit, email }: SetPasswordProps) => {
    const { formatMessage: fm } = useIntl();
    const [loading, setLoading] = React.useState(false);

    return (
        <Stack
            component="form"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                setLoading(true);
                const password = e.currentTarget.password.value as string;
                onSubmit(password).then(() => setLoading(false));
            }}
        >
            <Typography variant="h4" sx={{ mb: 2 }}>
                {"Create your password"}
            </Typography>
            <input type="hidden" name="email" value={email} autoComplete="username" />
            <VisibilityTextField autofocus name="password" label="Password" autoComplete="new-password" />
            <LoadingButton
                loading={loading}
                variant={active ? "contained" : "outlined"}
                color={active ? "primary" : "secondary"}
                sx={{ mb: 2, alignSelf: "end" }}
            >
                {fm(messages.next)}
            </LoadingButton>
        </Stack>
    );
};

type GetStartedProps = {
    onClick: () => void;
};
export const GetStarted = ({ onClick }: GetStartedProps) => {
    const { formatMessage: fm } = useIntl();
    return (
        <Stack>
            <Typography variant="h4" sx={{ mb: 2 }}>
                {fm(messages.headerGo)}
            </Typography>
            <LoadingButton
                loading={false}
                onClick={onClick}
                size="large"
                variant="contained"
                sx={{ mb: 2, alignSelf: "end" }}
            >
                {fm(messages.gotoApp)}
            </LoadingButton>
        </Stack>
    );
};

type Props = {
    workspace: {
        id: string;
        display: string;
    };
    invitedBy: {
        id: string;
        display: string;
    };
    user: {
        id: string;
        email: string;
    };
};
export const RegularSignUp = ({ workspace, invitedBy, user }: Props) => {
    const { formatMessage: fm } = useIntl();

    const [step, setStep] = React.useState(0);
    const steps: { step: string; header: string }[] = [
        { step: fm(messages.stepPassword), header: fm(messages.headerPassword) },
        { step: fm(messages.headerName), header: fm(messages.headerName) },
        { step: fm(messages.stepGo), header: fm(messages.headerGo) },
    ];
    const [alert, setAlert] = React.useState("");

    async function onSetPassword(pw: string) {
        const { error } = await updatePassword(pw);
        if (error) {
            track("onboarding: failed to set password", { error });
            Sentry.captureException(error);
            setAlert(error.details || error.code); // TODO: prettier
            return;
        }
        setStep(1);
    }

    async function onSetName(first: string, last: string) {
        const { error } = await updateName(first, last);
        if (error) {
            track("onboarding: failed to set name", { error });
            Sentry.captureException(error);
            return;
        }
        setStep(2);
    }
    async function onGetStarted() {
        track("onboarding: complete, go to app");
        window.location.href = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_DASHBOARD_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_DASHBOARD_URL;
    }

    return (
        <Layout>
            <Header invitedBy={invitedBy} workspace={workspace} />
            <Stepper activeStep={step} sx={{ mb: 4 }} alternativeLabel>
                {steps.map(({ step: stepTitle }) => (
                    <Step key={stepTitle}>
                        <StepLabel>{stepTitle}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Stack gap={2} maxWidth={550} sx={{ mb: 4, mt: 12, mx: "auto" }} alignItems="stretch">
                {alert != "" && (
                    <Alert onClose={() => setAlert("")} severity="error">
                        {alert}
                    </Alert>
                )}
                <SetPassword active={step == 0} onSubmit={onSetPassword} email={user.email || ""} />
                <Collapse in={step > 0}>
                    <Stack color={step == 1 ? "text.primary" : "text.secondary"}>
                        <SetName active={step == 1} onSubmit={onSetName} />
                    </Stack>
                </Collapse>
                <Collapse in={step > 1}>
                    <GetStarted onClick={onGetStarted} />
                </Collapse>
            </Stack>
        </Layout>
    );
};
