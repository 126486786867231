import { Stack, TextField } from "@mui/material";
import { useRef, useState } from "react";

type Props = {
    onChange: (code: string) => void;
};
export const OTPInput = ({ onChange }: Props) => {
    const EMPTY = ["", "", "", "", "", ""];
    const [digits, setDigits] = useState(EMPTY);
    const refs = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
    ];
    function focus(i: number) {
        if (i < 0 || i >= 6) return;
        const curr = refs[i].current;
        if (curr) {
            curr.focus();
        }
    }
    function clear(i: number) {
        const newDigits = [...digits];
        newDigits[i] = "";
        setDigits(newDigits);
    }
    function hasDigit(i: number) {
        return digits[i] !== "";
    }

    return (
        <Stack gap={2} alignItems="center" direction="row">
            {digits.map((d, i) => (
                <TextField
                    key={i}
                    inputProps={{ style: { textAlign: "center", height: "40px", width: "32px" } }}
                    autoFocus={i === 0}
                    autoComplete="off"
                    inputRef={refs[i]}
                    variant="outlined"
                    value={d}
                    onPaste={(e) => {
                        e.preventDefault();
                        const pastedChars = e.clipboardData.getData("text").split("");
                        const updated = [...digits];
                        pastedChars.forEach((d, j) => {
                            if (i + j < 6) {
                                updated[i + j] = d;
                            }
                        });
                        if (pastedChars.length + i < 6) {
                            focus(pastedChars.length + i);
                        }
                        setDigits(updated);
                        onChange(updated.join(""));
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                            clear(hasDigit(i) ? i : i - 1);
                            focus(i - 1);
                        }
                        if (e.key === "ArrowLeft" || e.key == "h") {
                            focus(i - 1);
                        }
                        if (e.key === "ArrowRight" || e.key == "l") {
                            focus(i + 1);
                        }

                        // accept digits, ignore others
                        if (isDigit(e.key)) {
                            const newDigits = [...digits];
                            newDigits[i] = e.key;
                            setDigits(newDigits);
                            onChange(newDigits.join(""));
                            focus(i + 1);
                        }
                    }}
                    sx={{
                        alignItems: "center",
                        alignSelf: "stretch",
                        gap: "8px",
                        borderRadius: "8px",
                        background: "#FFF",
                        boxShadow: "0px 1px 2px 0px rgba(13, 16, 23, 0.06)",
                    }}
                />
            ))}
        </Stack>
    );
};

const isDigit = (str: string) => str.length === 1 && str >= "0" && str <= "9";
