import { defineMessages } from "react-intl";

const messages = defineMessages({
    ssoDescription2: {
        defaultMessage: "Please sign in to Microsoft with {email} to continue.",
        id: "token.ssoDescription2",
    },
    ssoDescription: {
        defaultMessage: "The administrator of this workspace has enabled single sign-on.",
        id: "token.ssoDescription",
    },
    toApp: {
        defaultMessage: "Take me to the app",
        id: "token.toApp",
    },
    withMicrosoft: {
        defaultMessage: "Log in with Microsoft",
        id: "token.withMicrosoft",
    },
    withPwcId: {
        defaultMessage: "Log in with PwC ID",
        id: "token.withPwcId",
    },
    subtitle: {
        defaultMessage: "We're excited to have you on board!",
        id: "token.subtitle",
    },
    gotoApp: {
        defaultMessage: "Take me to the app",
        id: "token.gotoApp",
    },
    next: {
        defaultMessage: "Next",
        id: "token.next",
    },
    later: {
        defaultMessage: "Maybe later",
        id: "token.later",
    },
    headerGo: {
        defaultMessage: "Let's get started!",
        id: "token.headerGo",
    },
    stepGo: {
        defaultMessage: "Let's go!",
        id: "token.stepGo",
    },
    headerName: {
        defaultMessage: "What should we call you?",
        id: "token.headerName",
    },
    optional: {
        defaultMessage: "Optional",
        id: "token.optional",
    },
    headerPassword: {
        defaultMessage: "Create your password",
        id: "token.headerPassword",
    },
    stepPassword: {
        defaultMessage: "Create your password",
        id: "token.stepPassword",
    },
    title: {
        defaultMessage: "You have been invited to {name} by {inviter}",
        id: "token.title",
    },
    titleNoInviter: {
        defaultMessage: "You have been invited to {name}",
        id: "token.titleNoInviter",
    },
    not_found: {
        defaultMessage: "No invitation found matching the token",
        id: "token.not_found",
    },
    not_found_subtitle: {
        defaultMessage: "Please contact the person who invited you to get a new invitation",
        id: "token.not_found_subtitle",
    },
    already_completed: {
        defaultMessage: "This invitation has already been completed.",
        id: "token.already_completed",
    },
    already_completed_subtitle: {
        defaultMessage: "You should be able to log in. If you have any issues, please contact support.",
        id: "token.already_completed_subtitle",
    },
    expired: {
        defaultMessage: "This invitation has expired.",
        id: "token.expired",
    },
    expired_subtitle: {
        defaultMessage: "Please contact the person who invited you to get a new invitation",
        id: "token.expired_subtitle",
    },
    internal_server_error: {
        defaultMessage: "Something went wrong while preparing this invite. Please try again later.",
        id: "token.internal_server_error",
    },
    internal_server_error_subtitle: {
        defaultMessage: "We're working on resolving the issue. Please try again later.",
        id: "token.internal_server_error_subtitle",
    },
});

export default messages;
