import { SupportedLanguages } from "@/types";
import { useEffect, useState } from "react";

export function usePreferredLanguage() {
    const localStorageLocale = localStorage.getItem("locale");

    // TODO: we can use navigator.languages, similar to how it's done in assessment-form/src/App.tsx
    const [current, setCurrent] = useState<SupportedLanguages | "INVALID_LANGUAGE">("en-US");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        switch (localStorageLocale) {
            case "en-US":
            case "nb-NO":
                setCurrent(localStorageLocale);
                break;
            case "":
            case null:
                setCurrent("en-US");
                break;
            default:
                setCurrent("INVALID_LANGUAGE");
                break;
        }
    }, [localStorageLocale]);

    return {
        current,
        loading,
        valid: current !== "INVALID_LANGUAGE",
        set: async (lang: SupportedLanguages): Promise<boolean> => {
            if (!lang) return false;
            setLoading(true);
            localStorage.setItem("locale", lang);
            const res = await fetch(import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL + "/api/v1/update-locale", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ locale: lang }),
            });
            setLoading(false);
            if (res.status !== 200) {
                return false;
            }
            setCurrent(lang);
            return true;
        },
    };
}
