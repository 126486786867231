import { defineMessages } from "react-intl";

const messages = defineMessages({
    profile: {
        defaultMessage: "Profile",
        id: "profile",
    },
    logOutText: {
        defaultMessage: "Log out",
        id: "logOutText",
    },
    goToApp: {
        defaultMessage: "Go to app",
        id: "goToApp",
    },
    "navbar.companylogo.header": {
        id: "navbar.companylogo.header",
        defaultMessage: "Welcome, {firstname} {lastname}",
    },
    "navbar.userprofile.text": {
        id: "navbar.userprofile.text",
        defaultMessage: "User Profile",
    },
});

export default messages;
