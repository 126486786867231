import SVG from "@/assets/images/logo-icon-only.svg";

type Props = {
    width: string;
};
export const IgniteIcon: React.FC<Props> = ({ width }) => {
    return (
        <a href="https://igniteprocurement.com/" rel="noreferrer" target="_blank">
            <img width={width} src={SVG} alt="Ignite Procurement" />
        </a>
    );
};
