import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { Stack, Container, Paper, Typography, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { oidcLink } from "@/hooks/settings";
import { logout, useSession } from "@/hooks/useSession";
import { track } from "@ignite-analytics/track";
import { useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";
import messages from "./messages";
import { SSOProviderMicrosoft } from "@/consts";
import getProvider from "@/utils/getProvider";

const Layout = ({ children }: { children: React.ReactNode }) => (
    <Container component={Paper} elevation={6} maxWidth="sm" sx={{ p: 4 }}>
        {children}
    </Container>
);

export const LinkAccountPage = () => {
    const [search] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const s = useSession();
    const providerId = search.get("provider") as string | null;
    const [alert, setAlert] = useState<string | null>(null);

    const { formatMessage } = useIntl();
    async function onLink() {
        if (!s.data) {
            setAlert(formatMessage(messages.genericError));
            Sentry.captureMessage("failed to link account: no session");
            return;
        }
        const { data, error } = await oidcLink(SSOProviderMicrosoft, s.data.traits.email);
        if (error) {
            Sentry.captureMessage("failed to link account", {
                tags: { ...error },
                user: { id: s.data.userId, email: s.data.traits.email },
            });
            setAlert(formatMessage(messages.genericError));
            return;
        }
        track("account link: start linking");
        window.location.href = data.redirect;
    }

    async function onGoBack() {
        track("account link: abort");
        await logout();
    }

    const loadOnLink = async () => {
        setLoading(true);
        await onLink();
        setLoading(false);
    };

    const provider = getProvider(providerId ?? "");
    if (provider == null) {
        window.location.href = "/";
        return null;
    }
    return (
        <Layout>
            <Stack gap={2} alignItems="start">
                {alert && <Alert severity="info">{alert}</Alert>}
                <Typography variant="h4">{formatMessage(messages.title, { provider: provider.name })}</Typography>
                <Typography variant="body1">{formatMessage(messages.subtitle, { provider: provider.name })}</Typography>

                <Stack direction="row" gap={3} alignSelf="end">
                    <LoadingButton variant="text" onClick={onGoBack} loading={false} color="secondary">
                        {formatMessage(messages.back)}
                    </LoadingButton>

                    <LoadingButton
                        variant="contained"
                        size="large"
                        startIcon={<img width={24} src={provider.iconSrc} />}
                        loading={loading}
                        onClick={loadOnLink}
                    >
                        {formatMessage(messages.btnLink, { provider: provider.name })}
                    </LoadingButton>
                </Stack>
            </Stack>
        </Layout>
    );
};
