import { defineMessages } from "react-intl";

const messages = defineMessages({
    welcomeBack: {
        defaultMessage: "Welcome back",
        id: "welcomeBack"
    },
    sessionExpiredAlert: {
        defaultMessage: "Your session has expired. Please log in again.",
        id: "sessionExpiredAlert",
    },
    revertLink: {
        defaultMessage: "old login page",
        id: "revertLink",
    },
    "4000006": {
        id: "login.4000006",
        defaultMessage:
            "The provided credentials are invalid, check for spelling mistakes in your password or username, email address.",
    },
    "4000007": {
        id: "login.4000007",
        defaultMessage: "An account with the same identifier (email, phone, username, ...) exists already.",
    },
    "4000008": {
        id: "4000008",
        defaultMessage: "The provided authentication code is invalid, please try again.",
    },
    "4000010": {
        id: "4000010",
        defaultMessage: "Account not active yet. Did you forget to verify your email address?",
    },
    "4000012": {
        id: "4000012",
        defaultMessage: "This backup recovery code has already been used.",
    },
    "4000015": {
        id: "4000015",
        defaultMessage: "This account does not exist or has no security key set up.",
    },
    "4000027": {
        id: "login.4000027",
        defaultMessage:
            "An account with the same identifier exists already. Please sign in to your existing account and link your social profile in the settings page.",
    },
    "1010004": {
        id: "1010004",
        defaultMessage: "Please enter the 6 digit code from your authenticator app.",
    },
    genericError: {
        id: "login.genericError",
        defaultMessage: "Something went wrong, our team has been notified.",
    },
    loginTitle: {
        id: "loginTitle",
        defaultMessage: "Sign into your account",
    },
});

export default messages;
