import {
    UiNode,
    UiNodeAttributes,
    UiNodeImageAttributes,
    UiNodeInputAttributes,
    UiNodeTextAttributes,
} from "@ory/kratos-client";
import { useCallback, useEffect, useState } from "react";

import { useCheckPrivilegedSession } from "@/hooks/settings";
import { AuthenticationError, useHandleKratosErrorResponse } from "@/hooks/useKratosErrorResponse";
import { oryClient } from "@/hooks/useSession";

function isUiNodeImageAttributes(attributes: UiNodeAttributes): attributes is UiNodeImageAttributes {
    return attributes.node_type === "img";
}

function isUiNodeTextAttributes(attributes: UiNodeAttributes): attributes is UiNodeTextAttributes {
    return attributes.node_type === "text";
}

function isUiNodeInputAttributes(attributes: UiNodeAttributes): attributes is UiNodeInputAttributes {
    return attributes.node_type === "input";
}

export function useTotpRegistration() {
    const [loading, setLoading] = useState({
        general: false,
        verify: false,
        remove: false,
        generate: false,
        confirm: false,
    });
    const [errors, setErrors] = useState<AuthenticationError[]>();
    const kratosErrors = useHandleKratosErrorResponse({});
    const { privilegedSession, redirect } = useCheckPrivilegedSession();
    const [qrNodeAttributes, setQRNodeAttributes] = useState<UiNodeImageAttributes>();
    const [secretKeyNodeAttributes, setSecretKeyNodeAttributes] = useState<UiNodeTextAttributes>();
    const [lookupSecretNodeAttributes, setLookupSecretNodeAttributes] = useState<UiNodeTextAttributes>();
    const [csrfNodeAttributes, setCsrfNodeAttributes] = useState<UiNodeInputAttributes>();
    const [flowId, setFlowId] = useState<string>();

    const parseUiNodes = useCallback(
        (nodes: UiNode[]) => {
            nodes.forEach((node) => {
                const { attributes } = node;
                if (node.group === "totp" && isUiNodeImageAttributes(attributes)) {
                    setQRNodeAttributes(attributes);
                }
                if (node.group === "totp" && isUiNodeTextAttributes(attributes)) {
                    setSecretKeyNodeAttributes(attributes);
                }
                if (node.group === "lookup_secret" && isUiNodeTextAttributes(attributes)) {
                    setLookupSecretNodeAttributes(attributes);
                }
                if (node.group === "default" && isUiNodeInputAttributes(attributes)) {
                    setCsrfNodeAttributes(attributes);
                }
            });
        },
        [setQRNodeAttributes, setSecretKeyNodeAttributes, setCsrfNodeAttributes, setLookupSecretNodeAttributes]
    );

    const loadUiNodes = useCallback(() => {
        setLoading((oldState) => ({ ...oldState, general: true }));
        oryClient.createBrowserSettingsFlow().then(({ data }) => {
            setFlowId(data.id);
            parseUiNodes(data.ui.nodes);
            setLoading((oldState) => ({ ...oldState, general: false }));
        });
    }, [parseUiNodes]);

    useEffect(loadUiNodes, [loadUiNodes]);

    const onVerify = (code: string) => {
        if (!privilegedSession) {
            redirect({ redirect_url: "/settings/authentication" });
        }
        if (flowId && csrfNodeAttributes) {
            setLoading((oldState) => ({ ...oldState, verify: true }));
            oryClient
                .updateSettingsFlow({
                    flow: flowId,
                    updateSettingsFlowBody: {
                        csrf_token: csrfNodeAttributes.value,
                        method: "totp",
                        totp_code: code,
                    },
                })
                .then(() => setQRNodeAttributes(undefined))
                .catch((err) => {
                    const response = kratosErrors.errorHandler(err);
                    if (response.errors) {
                        setErrors(response.errors);
                    }
                })
                .finally(() => setLoading((oldState) => ({ ...oldState, verify: false })));
        }
    };

    const generateRecoveryCodes = () => {
        if (!privilegedSession) {
            redirect({ redirect_url: "/settings/authentication" });
        }
        if (flowId && csrfNodeAttributes) {
            setLoading((oldState) => ({ ...oldState, generate: true }));
            oryClient
                .updateSettingsFlow({
                    flow: flowId,
                    updateSettingsFlowBody: {
                        csrf_token: csrfNodeAttributes.value,
                        method: "lookup_secret",
                        lookup_secret_regenerate: true,
                    },
                })
                .then(({ data }) => parseUiNodes(data.ui.nodes))
                .finally(() => setLoading((oldState) => ({ ...oldState, generate: false })));
        }
    };

    const confirmRecoveryCodes = () => {
        if (!privilegedSession) {
            redirect({ redirect_url: "/settings/authentication" });
        }
        if (flowId && csrfNodeAttributes) {
            setLoading((oldState) => ({ ...oldState, confirm: true }));
            oryClient
                .updateSettingsFlow({
                    flow: flowId,
                    updateSettingsFlowBody: {
                        csrf_token: csrfNodeAttributes.value,
                        method: "lookup_secret",
                        lookup_secret_confirm: true,
                    },
                })
                .then(() => setLookupSecretNodeAttributes(undefined))
                .finally(() => setLoading((oldState) => ({ ...oldState, confirm: false })));
        }
    };

    const removeMFA = () => {
        if (!privilegedSession) {
            redirect({ redirect_url: "/settings/authentication" });
        }
        if (flowId && csrfNodeAttributes) {
            setLoading((oldState) => ({ ...oldState, remove: true }));
            oryClient
                .updateSettingsFlow({
                    flow: flowId,
                    updateSettingsFlowBody: {
                        csrf_token: csrfNodeAttributes.value,
                        method: "totp",
                        totp_unlink: true,
                    },
                })
                .then(loadUiNodes)
                .finally(() => setLoading((oldState) => ({ ...oldState, remove: false })));
        }
    };

    return {
        loading,
        qrNodeAttributes,
        secretKeyNodeAttributes,
        lookupSecretNodeAttributes,
        errors,
        onVerify,
        removeMFA,
        generateRecoveryCodes,
        confirmRecoveryCodes,
    };
}
