import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { getCurrentDomain } from "@/utils/getCurrentHostname";
import messages from "./messages";

const baseUrl = "https://api.igniteprocurement.com/boot/";

export function ConfiguringPage() {
    const { formatMessage } = useIntl();
    const [attempt, setAttempt] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            fetch(baseUrl, { credentials: "include" })
                .then((res) => {
                    if (res.ok) {
                        const redirectUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_APP_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
                        window.location.replace(redirectUrl);
                    } else {
                        setAttempt(attempt + 1);
                    }
                })
                .catch(() => setAttempt(attempt + 1));
        }, 15000);
    }, [attempt]);

    // We need to force the type in order for the message to have the correct type
    const attemptKey = (attempt % 10) as keyof typeof messages;

    return (
        <Container maxWidth="sm">
            <Stack alignItems="center" spacing={4}>
                <CircularProgress />
                <Typography textAlign="center" variant="h4" fontWeight={400}>
                    {formatMessage(messages[attemptKey])}
                </Typography>
            </Stack>
        </Container>
    );
}
