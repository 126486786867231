import React from "react";
import { Stack, Container, Paper } from "@mui/material";

export const Layout = ({ children }: { children: React.ReactNode }) => (
    <Stack sx={{ minHeight: "80vh", alignItems: "center", justifyContent: "center", p: 4 }}>
        <Container component={Paper} elevation={6} maxWidth="md" sx={{ minHeight: "600px", p: 4 }}>
            {children}
        </Container>
    </Stack>
);
