import { defineMessages } from "react-intl";

const messages = defineMessages({
    yourMail: {
        defaultMessage: "Your email: {email}",
        id: "yourMail"},
    askAdmin: {
        defaultMessage: "Contact your workspace administrator and ask them to invite you to the workspace.",
        id: "askAdmin"
    },
    useIntercom: {
        defaultMessage: "Or, use the Intercom Chat bubble in the bottom left corner of the screen to contact our support team.",
        id: "useIntercom"
    },
    ifDelayed: {
        defaultMessage: "If you're still seeing this message after 2 minutes, please try the following:",
        id: "ifDelayed"
    },
    pleaseWait: {
        defaultMessage: "Please wait around 2 minutes while we're setting up your account.",
        id: "pleaseWait"
    },
    settingUp: {
        defaultMessage: "Setting up your account...",
        id: "settingUp"
    },
    noWorkspaces: {
        defaultMessage: "It looks like you don't have access to any workspaces...",
        id: "noWorkspaces"
    },
    backToLogin: {
        defaultMessage: "Back to login",
        id: "backToLogin"
    },
});

export default messages;

