import {
    AppBar,
    Box,
    Button,
    IconButton,
    Link,
    ListItemIcon,
    MenuItem,
    Menu as MuiMenu,
    Stack,
    Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";

import Logo from "@/assets/main-logo.svg";

import { logout, useSession } from "@/hooks/useSession";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import messages from "./messages";
import { ArrowRight, Gear } from "@ignite-analytics/icons";

const NavBar = () => {
    const sess = useSession();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        setAnchorEl(e.currentTarget);
        e.preventDefault();
    }
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const appUrl =
        getCurrentDomain() === "ignite"
            ? import.meta.env.VITE_IGNITE_APP_URL
            : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
    return (
        // app bar style is a copy-paste of web/src/containers/Base/Navbar
        <AppBar
            elevation={1}
            position="fixed"
            sx={{
                zIndex: 1,
                background: "white",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#ddd",
            }}
        >
            <Toolbar variant="dense">
                <Stack sx={{ cursor: "pointer", marginLeft: "2px" }} py={0.5} alignItems="center">
                    <Link to={appUrl} component={NavLink}>
                        <img width={27} alt="Ignite Procurement Logo" src={Logo} />
                    </Link>
                </Stack>

                {/* left side */}
                <Box sx={{ flexGrow: 1 }} />
                {/* right side */}

                <Button
                    onClick={() => (window.location.href = appUrl)}
                    disabled={!sess.data}
                    color="secondary"
                    variant="outlined"
                    size="small"
                >
                    {formatMessage(messages.goToApp)}
                </Button>

                {/* profile drop down */}
                <IconButton onClick={handleClick}>
                    <Gear />
                </IconButton>
                <MuiMenu
                    open={anchorEl != null}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            navigate("/settings");
                        }}
                    >
                        <ListItemIcon>
                            <Gear />
                        </ListItemIcon>
                        {formatMessage(messages.profile)}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            {
                                setAnchorEl(null);
                                logout();
                            }
                        }}
                    >
                        <ListItemIcon>
                            <ArrowRight />
                        </ListItemIcon>
                        {formatMessage(messages.logOutText)}
                    </MenuItem>
                </MuiMenu>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
