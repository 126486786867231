import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Recovery codes",
        id: "LookupSecretModal.title",
    },
    description: {
        defaultMessage:
            "These recovery codes are a set of server-generated one-time codes. You should store these codes in a secure place and use them when you cannot use your selected 2FA method. \nEach code is valid for single use only. The codes don't expire after a set amount of time. If you generate a new set of codes, previously generated codes become invalid. You need to confirm the codes in order for them to become valid.",
        id: "LookupSecretModal.description",
    },
    confirmCodes: {
        defaultMessage: "Confirm codes",
        id: "LookupSecretModal.confirmCodes",
    },
});

export default messages;
