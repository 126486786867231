import { Alert, Divider, Link, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { SSOProviderMicrosoft } from "@/consts";
import { oidcLink, oidcUnlink, updatePassword, useCheckPrivilegedSession, useLinkedProviders } from "@/hooks/settings";
import MicrosoftLogo from "../../../assets/ms-logo.svg";
import messages from "../messages";

import { useSnackMessageContext } from "@/contexts/SnackMessageContext";
import { useSession } from "@/hooks/useSession";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { track } from "@ignite-analytics/track";
import Providers from "./Providers";
import { TOPTSection } from "./TOPTSection";
import { UpdatePassword } from "./UpdatePassword";

const AuthenticationPage: React.FC = () => {
    const { privilegedSession, redirect } = useCheckPrivilegedSession();
    const sess = useSession();
    // if you have a mid in metadataPublic, you're scimmed
    const isScimmed = !!sess?.data?.session?.identity?.metadata_public?.microsoftId;
    const { formatMessage } = useIntl();
    const [error, _setError] = useState<string | null>(null);
    const [submitPasswordLoading, setSubmitPasswordLoading] = useState(false);
    const providers = useLinkedProviders();

    const { onMessage } = useSnackMessageContext();

    const onSubmitNewPassword = useCallback(
        async (pw: string): Promise<void> => {
            setSubmitPasswordLoading(true);
            const { error } = await updatePassword(pw);
            if (error != null) {
                track("password update: failed", { code: error.code });
                switch (error.code) {
                    case "session_refresh_required":
                        sessionStorage.setItem("updatePasswordKey", pw);
                        redirect({ redirect_url: "/settings/authentication", email: sess?.data?.traits?.email });
                        break;
                    default:
                        if (error.code == "unknown_error" || error.code == "session_not_found") {
                            Sentry.captureMessage("failed to update password", {
                                extra: error,
                                tags: { details: error.details },
                            });
                        }
                        break;
                }
                _setError(formatMessage(messages[error.code]));
                setSubmitPasswordLoading(false);
                return;
            }
            track("password update: succeeded");
            onMessage(formatMessage(messages.passwordUpdated));
            setSubmitPasswordLoading(false);
        },
        [sess, redirect, formatMessage, onMessage]
    );

    useEffect(() => {
        const pw = sessionStorage.getItem("updatePasswordKey");
        if (pw) {
            sessionStorage.removeItem("updatePasswordKey");
            onSubmitNewPassword(pw);
        }
    }, [onSubmitNewPassword]);

    const [displayLinkSuccess, setDisplayLinkSuccess] = useState(false);
    useEffect(() => {
        if (providers.linked.microsoft === null) {
            // undetermined while loading, do nothing
            return;
        }
        const key = "ms-link-state";
        const write = () => {
            localStorage.setItem(key, JSON.stringify({ enabled: providers.linked.microsoft }));
        };
        if (localStorage.getItem(key) != null) {
            const { enabled } = JSON.parse(localStorage.getItem(key) as string);
            if (enabled === false && providers.linked.microsoft) {
                setDisplayLinkSuccess(true);
            }
        }
        write();
    }, [privilegedSession, providers.linked.microsoft]);

    const validErrors = [{ message: error, clear: () => _setError(null) }].filter(({ message }) => !!message);

    const dashboardUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_DASHBOARD_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_DASHBOARD_URL;
    return (
        <Stack gap={2}>
            <Stack>
                {validErrors.map((e, i) => (
                    <Alert key={i} onClose={e.clear} severity="error">
                        {e.message}
                    </Alert>
                ))}
            </Stack>

            <Stack>
                {displayLinkSuccess && (
                    <Alert sx={{ mb: 4 }} onClose={() => setDisplayLinkSuccess(false)} severity="success">
                        <Typography variant="body2"> {formatMessage(messages.linkSuccess)}</Typography>
                        <Link color="primary" href={dashboardUrl} target="_blank">
                            {formatMessage(messages.linkSuccessHref)}
                        </Link>
                    </Alert>
                )}
                {isScimmed && (
                    <Alert severity="info" variant="outlined">
                        {formatMessage(messages.scimmed)}
                    </Alert>
                )}
            </Stack>

            <Stack rowGap={4}>
                {/* Social-Sign in providers */}
                <Providers
                    disabled={isScimmed}
                    beforeSubmit={() => {
                        if (!privilegedSession) {
                            redirect({ redirect_url: "/settings/authentication", expandPassword: "true" });
                            return false;
                        }
                        return true;
                    }}
                    providers={[
                        {
                            name: "Microsoft",
                            enabled: providers.linked.microsoft,
                            link: async () => {
                                const { data, error } = await oidcLink(SSOProviderMicrosoft);
                                if (error) {
                                    Sentry.captureMessage("failed to link microsoft", {
                                        tags: {
                                            code: error.code,
                                            details: error.details,
                                        },
                                    });
                                    return false;
                                }
                                window.location.href = data.redirect;
                                return true;
                            },
                            unlink: async () => {
                                const { error } = await oidcUnlink(SSOProviderMicrosoft);
                                if (error) {
                                    Sentry.captureMessage("failed to unlink microsoft", {
                                        tags: {
                                            code: error.code,
                                            details: error.details,
                                        },
                                    });
                                    return false;
                                }
                                await providers.refetch();
                                return true;
                            },
                            loading: providers.loading,
                            logo: MicrosoftLogo,
                        },
                    ]}
                />

                {/* password update */}
                <Stack rowGap={2} py={4}>
                    <Divider />
                    <Typography variant="h4">{formatMessage(messages.passwordSettingsHeader)}</Typography>
                    <UpdatePassword
                        loading={submitPasswordLoading}
                        onSubmit={onSubmitNewPassword}
                        disabled={isScimmed}
                    />
                </Stack>
            </Stack>
            { !isScimmed && (
                <TOPTSection />
            )}
        </Stack>
    );
};
export default AuthenticationPage;
