import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useSearchParams } from "react-router-dom";

import { oryClient } from "@/hooks/useSession";
import { Settings400Error, SettingsBrowserLocationChangeRequired } from "@/types";

import messages from "./messages";
import { collectErrorMessages } from "@/hooks/useKratosErrorResponse";

function useRegistration() {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState({ password: false, socialSignIn: { microsoft: false } });

    const { formatMessage } = useIntl();

    // redirect to current page after registration, keeping any query params
    const loc = useLocation();
    const [query] = useSearchParams();
    const returnTo = `${loc.pathname}/${query.toString()}`;

    function handle400(err: Settings400Error) {
        const errs = collectErrorMessages(err);
        if (errs.length == 0) {
            setError(formatMessage(messages.genericError));
            return;
        } else {
            errs.forEach((code) => {
                const messageKey = String(code.id) as keyof typeof messages;
                if (messages[messageKey]) {
                    setError(formatMessage(messages[messageKey]));
                } else {
                    setError(code.text ?? formatMessage(messages.genericError));
                }
            });
        }

        let errorCode = "unknown";
        if (errs.length > 0) {
            errorCode = errs[0].id.toString();
        }
        Sentry.captureMessage("Failed to register user", {
            level: "info",
            tags: { code: errorCode },
            extra: { errorCodes: errs },
        });
    }

    async function initFlow(): Promise<{ csrfToken: string; flow: string }> {
        const { data } = await oryClient.createBrowserRegistrationFlow({ returnTo });
        const csrfNode = data.ui.nodes.find((e) => e.group === "default");
        if (!csrfNode) {
            setError("failed to initialize login flow");
            Sentry.captureMessage("failed to initialize registration flow - no csrfNode found");
            return Promise.reject();
        }
        const attributes = csrfNode.attributes as unknown as { value: string };
        const csrfToken = attributes.value;
        const flow = data.id;
        return { csrfToken, flow };
    }

    async function registerPassword(password: string, email: string, name: { first: string; last: string }) {
        setLoading({ ...loading, password: true });
        const { csrfToken, flow } = await initFlow();
        try {
            await oryClient.updateRegistrationFlow({
                flow,
                updateRegistrationFlowBody: {
                    csrf_token: csrfToken,
                    password,
                    method: "password",
                    traits: { email: email.toLowerCase(), name },
                },
            });
        } catch (e) {
            const err = e as Settings400Error | SettingsBrowserLocationChangeRequired;
            if (err.response.status == 400) {
                handle400(err as Settings400Error);
            } else {
                Sentry.captureMessage("got unexpected status code when registering", {
                    tags: { status: err.response.status },
                });
                setError(formatMessage(messages.genericError));
            }
            setLoading({ ...loading, password: false });
            return Promise.reject();
        }
        setLoading({ ...loading, password: false });
        return Promise.resolve();
    }

    async function registerMicrosoft(email?: string | null) {
        const { csrfToken, flow } = await initFlow();
        try {
            await oryClient.updateRegistrationFlow({
                flow,
                updateRegistrationFlowBody: {
                    csrf_token: csrfToken,
                    method: "oidc",
                    provider: "microsoft",
                    upstream_parameters: {
                        login_hint: email ?? null,
                    },
                },
            });
        } catch (e) {
            const err = e as Settings400Error | SettingsBrowserLocationChangeRequired;
            if (err.response.status == 422) {
                const err = e as SettingsBrowserLocationChangeRequired;
                window.location.href = err.response?.data.redirect_browser_to;
            } else {
                Sentry.captureMessage("got unexpected status code when registering with microsoft", {
                    tags: { status: err.response.status },
                });
                setError(formatMessage(messages.genericError));
            }
        }
    }

    return {
        loading,
        error,
        register: {
            password: registerPassword,
            socialSignIn: {
                microsoft: registerMicrosoft,
            },
        },
    };
}

export default useRegistration;
