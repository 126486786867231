import { defineMessages } from "react-intl";

const messages = defineMessages({
    titleErrorGeneric: {
        defaultMessage: "An error occurred",
        id: "titleErrorGeneric",
    },
    btnSettings: {
        defaultMessage: "Back to settings",
        id: "btnSettings",
    },
    btnLogin: {
        defaultMessage: "Back to login",
        id: "btnLogin",
    },
    unknownErrorTitle: {
        defaultMessage: "An unknown error occurred",
        id: "unknownErrorTitle",
    },
    4060004: {
        defaultMessage: "The recovery link has already been used. Please request a new one.",
        id: "4060004",
    },
    recoveryGone: {
        defaultMessage: "The recovery link has expired. Please request a new one.",
        id: "recoveryGone",
    },
    410: {
        defaultMessage: "The recovery link has expired. Please request a new one.",
        id: "410",
    },
    404: {
        defaultMessage: "The recovery link is invalid. Please request a new one.",
        id: "404",
    },
});

export default messages;
