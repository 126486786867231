import IgniteLogo from "@/assets/images/logo-icon-only.svg";
import { Stack, Card, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
const RecoverySent = () => {
    const email = sessionStorage.getItem("email") ?? "";
    return (
        <Card sx={{ py: 4, px: 3, width: "444px" }}>
            <Stack gap={4.5}>
                {/* Symbol */}
                <Stack direction="row" justifyContent="center">
                    <img src={IgniteLogo} alt="email verified" />
                </Stack>

                {/*header*/}
                <Stack gap={3} alignItems="center">
                    <Typography variant="textXl" fontWeight="500">
                        <FormattedMessage defaultMessage="Recover your password" />
                    </Typography>
                    <Typography variant="textMd" color="textSecondary" align="center">
                        <FormattedMessage
                            defaultMessage="A recovery email has been sent to your email: {email}"
                            values={{
                                email: (
                                    <Typography variant="inherit" fontWeight="500">
                                        {email}
                                    </Typography>
                                ),
                            }}
                        />
                    </Typography>
                    <Typography variant="textSm" color="textSecondary" align="center">
                        <FormattedMessage defaultMessage="Please check your inbox and follow the instructions provided in your email to recover your password." />
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
};
export default RecoverySent;
