import { SSOProvider } from "@/types";
import { SSOProviderMicrosoft, SSOProviderPwc, SSOProviderGEA} from "@/consts";


const SSOProviders: Record<string, SSOProvider> = {
    "microsoft": SSOProviderMicrosoft,
    "vtBaMgf9": SSOProviderPwc,
    "pwcid": SSOProviderPwc,
    "j7jqNK0_": SSOProviderGEA,
    "geaokta": SSOProviderGEA,
    "gea-okta": SSOProviderGEA,
};

export const getProvider = (providerId: string): SSOProvider | null =>  {
    return SSOProviders[providerId] || null;
}

export default getProvider;
