import IgniteLogo from "@/assets/images/logo-icon-only.svg";
import { Stack, Card, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
const RegisterComplete = () => {
    const email = sessionStorage.getItem("email") ?? "";
    return (
        <Card sx={{ py: 4, px: 3, width: "444px" }}>
            <Stack gap={4.5}>
                {/* Symbol */}
                <Stack direction="row" justifyContent="center">
                    <img src={IgniteLogo} alt="email verified" />
                </Stack>

                {/*header*/}
                <Stack gap={3} alignItems="center">
                    <Typography variant="textXl" fontWeight="500">
                        <FormattedMessage defaultMessage="Welcome to Ignite!" />
                    </Typography>
                    <Typography variant="textMd" color="textSecondary" align="center">
                        <FormattedMessage
                            defaultMessage="A verification email has been sent to your email: {email}"
                            values={{
                                email: (
                                    <Typography variant="inherit" fontWeight="500">
                                        {email}
                                    </Typography>
                                ),
                            }}
                        />
                    </Typography>
                    <Typography variant="textSm" color="textSecondary" align="center">
                        <FormattedMessage defaultMessage="Before you can sign in you need to confirm that we have registered the right email address for your account. If you are unable to locate the email in your inbox, please check your spam folder." />
                    </Typography>
                    <Typography variant="textSm" color="textSecondary" align="center">
                        <FormattedMessage defaultMessage="You may close this window." />
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
};
export default RegisterComplete;
