import { LockSolid, User } from "@ignite-analytics/icons";
import {
    Stack,
    Container,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
    Link,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import messages from "./messages";
import NavBar from "./NavBar";

export const SettingsPage: React.FC = () => {
    const location = useLocation();
    const { formatMessage } = useIntl();

    const items = [
        { Icon: User, text: formatMessage(messages.sidebarProfile), href: `/settings/profile/` },
        {
            Icon: LockSolid,
            text: formatMessage(messages.sidebarAuthentication),
            href: `/settings/authentication/`,
        },
    ];

    return (
        <Stack bgcolor={(theme) => theme.palette.grey[100]} width="100%" direction="row" alignItems="flex-start">
            <NavBar />
            <Stack direction="row" width={300} pt={7}>
                <List sx={{ width: "100%" }}>
                    {items.map(({ text, href, Icon }) => {
                        const isActive = location.pathname.includes(href);
                        return (
                            <ListItem key={text} disablePadding>
                                <Link to={href} component={NavLink} style={{ flexGrow: 1 }}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <Stack color={isActive ? "text.primary" : "text.secondary"}>
                                                <Icon fontSize="small" />
                                            </Stack>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    color={isActive ? "text.primary" : "text.secondary"}
                                                    variant="subtitle2"
                                                >
                                                    {text}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
            <Stack component={Paper} minHeight="100vh" elevation={1} sx={{ flex: 1, px: 4 }}>
                <Container sx={{ m: 0, pt: 10 }} maxWidth="sm">
                    <Outlet />
                </Container>
            </Stack>
        </Stack>
    );
};
