import { oryClient } from "@/hooks/useSession";
import { SSOProvider } from "@/types";
import { SSOProviderMicrosoft } from "@/consts";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { ErrorGeneric, Session, UiContainer, UiText } from "@ory/kratos-client";
import axios, { AxiosError } from "axios";

interface ResponseJson {
    supportsSSO: boolean;
    ssoProvider?: string;
    requireSSO: boolean;
    domain?: string;
}

type LoginMethodResponse = {
    oidc: {
        provider: string;
        domain?: string;
    } | null;
    error: null;
};

type LoginMethodError = {
    oidc: null;
    error: {
        code:
            | "user_not_found"
            | "internal_server_error"
            | "network_error"
            | "generic_error"
            | "sso_required"
            | "no_login_methods";
        details?: string;
    };
};

type UpstreamParameters = {
    domain_hint?: string;
    login_hint?: string;
    prompt?: "none" | "login" | "consent" | "select_account";
};

export async function getAppropriateLoginMethod(email: string): Promise<LoginMethodResponse | LoginMethodError> {
    let res: Response;
    try {
        res = await fetch(`${import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL}/api/v1/get-available-login-methods`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, tenant: "" }),
        });
    } catch (e) {
        return { error: { code: "network_error", details: "network error" }, oidc: null };
    }

    if (res.status !== 200) {
        const e: LoginMethodError = { error: { code: "generic_error" }, oidc: null };
        switch (res.status) {
            case 404:
                e.error.code = "user_not_found";
                break;
            case 500:
            case 502:
                e.error.code = "internal_server_error";
                break;
            default:
                e.error.details = `${res.status}: unexpected status`;
        }
        return e;
    }

    const data: ResponseJson = await res.json();
    if (data.requireSSO && !data.supportsSSO) {
        return { error: { code: "sso_required" }, oidc: null };
    }

    return {
        oidc: data.ssoProvider
            ? {
                  provider: data.ssoProvider,
                  domain: data.domain,
              }
            : null,
        error: null,
    };
}

type BrowserLocationChangeRequired = {
    redirect_browser_to: string;
};

type ErrorGenericResponse = {
    error: {
        code: number;
        id: string; // error ID
        message: string;
        reason: string; // human-readable reason
    };
};
export function collectErrors(err: AxiosError<{ ui: UiContainer } | ErrorGenericResponse>): OryError[] {
    const rv: OryError[] = [];
    if (!err.response) {
        return [{ error: { code: "unknown_error" }, data: null }];
    }
    const { data } = err.response;

    // if data.error is defined, then it's an ErrorGenericResponse. And then I've been fighting the linter,
    // hence this ugly cast.
    if ((data as unknown as { error: null | unknown }).error) {
        // ErrorGenericResponse case.
        const v = data as ErrorGenericResponse;
        return [{ error: { code: v.error.id, details: v.error.message }, data: null }];
    }
    // Otherwise we assume the error is in the UiContainer
    const uiErr = err as AxiosError<{ ui: UiContainer }>;
    const ui = uiErr.response?.data.ui;
    if (ui?.messages) {
        ui.messages.forEach((e) => {
            const id = e.id;
            const code = idToCode[id] || `${id}`;
            const m = { error: { code, details: e.text }, data: null };
            if (errorCodes.includes(code)) {
                rv.push(m);
            } else {
                rv.push({ error: { code: "unknown_error", details: `${code}: ${e.text}` }, data: null });
            }
        });
    }
    if (ui?.nodes) {
        for (const k in ui?.nodes) {
            const node = ui.nodes[k];
            if (node.messages) {
                node.messages.forEach((e) => {
                    const id = e.id;
                    const code = idToCode[id] || `${id}`;
                    const m = { error: { code }, data: null };
                    if (errorCodes.includes(code)) {
                        rv.push(m);
                    } else {
                        rv.push({ error: { code: "unknown_error", details: `${code}: ${e.text}` }, data: null });
                    }
                });
            }
        }
    }
    return rv;
}

const errorCodes = [
    "aal2_required",
    "generic_error",
    "internal_server_error",
    "invalid_credentials",
    "network_error",
    "network_error",
    "no_login_methods",
    "sso_required",
    "session_not_found",
    "unknown_error",
    "user_not_found",
    "account_inactive",
];

type errorCode = (typeof errorCodes)[number];

export type OryError<T = errorCode> = {
    error: {
        code: T;
        details?: string;
    };
    data: null;
};

const errUnknown: OryError<"unknown_error"> = { error: { code: "unknown_error" }, data: null };

export function extractGenericError<T extends string>(
    err: AxiosError<ErrorGeneric>,
    expectedCodes: T[]
): OryError<T | "unknown_error"> {
    if (!err.response) {
        return errUnknown as OryError<T>;
    }
    const { data } = err.response;
    if (!data) {
        return errUnknown as OryError<T>;
    }
    const { id, message } = data.error;
    if (!id) {
        return errUnknown as OryError<T>;
    }
    const exp = expectedCodes as string[];
    if (exp.includes(id)) {
        const code = id as T;
        return { error: { code, details: message }, data: null };
    }
    return {
        error: {
            code: "unknown_error",
            details: `${id}: ${message}`,
        },
        data: null,
    };
}
export function extractErrorFromUi<T extends string>(
    err: AxiosError<{ ui: UiContainer }>,
    expectedCodes: readonly T[]
): OryError<T | "unknown_error"> {
    if (!err.response) {
        return errUnknown as OryError<T>;
    }
    const { data } = err.response;
    if (!data) {
        return errUnknown as OryError<T>;
    }
    const ui = data.ui;

    function handleMessage(m: UiText): OryError<T | "unknown_error"> {
        const id = m.id;
        const code = idToCode[id] || `${id}`;
        if (expectedCodes.includes(code as T)) {
            return { error: { code: code as T, details: m.text }, data: null };
        }
        const details = `${code}: ${m.text}`;
        return { error: { code: "unknown_error", details }, data: null };
    }

    for (const m of ui.messages || []) {
        return handleMessage(m);
    }
    for (const n of ui.nodes) {
        for (const m of n.messages || []) {
            return handleMessage(m);
        }
    }
    return errUnknown as OryError<T>;
}

export type LoginOpts = {
    refresh?: boolean;
    returnTo?: string;
    aal?: "aal1" | "aal2";
};

const initLoginFlowErrorCodes = ["unknown_error", "session_already_available", "network_error"] as const;
type InitLoginFlowError = OryError<(typeof initLoginFlowErrorCodes)[number]>;
async function initLoginFlow(
    opts: LoginOpts = { refresh: false, aal: "aal1" }
): Promise<{ error: null; data: { csrf: string; flow: string } } | InitLoginFlowError> {
    if (opts.returnTo === undefined) {
        opts.returnTo = getCurrentDomain() === "ignite" ? "https://auth.ignite.no/" : "/";
    }

    try {
        const { status, data } = await oryClient.createBrowserLoginFlow(opts);
        if (status !== 200) {
            return { error: { code: "unknown_error", details: `unexpected code ${status}` }, data: null };
        }
        const flow = data.id;
        const csrfNode = data.ui.nodes.find((e) => e.group === "default");
        if (!csrfNode) {
            return { error: { code: "unknown_error", details: "failed to find csrf node" }, data: null };
        }
        const attributes = csrfNode?.attributes as unknown as { value: string };
        const csrf: string = attributes.value;
        return { error: null, data: { csrf, flow } };
    } catch (e) {
        if (axios.isAxiosError(e)) {
            const err = e as AxiosError<ErrorGenericResponse>;
            return extractGenericError(err, ["session_already_available", "network_error"]);
        } else {
            return { error: { code: "unknown_error", details: "failed to initiate login flow" }, data: null };
        }
    }
}

type LoginErrorResponse = {
    ui: UiContainer;
};

const idToCode: Record<number, string> = {
    [4000006]: "invalid_credentials",
    [4000034]: "password_too_common",
    [666]: "sso_login_required",
    [668]: "not_verified",
    [667]: "no_workspaces_found",
    [4000008]: "invalid_authentication_code",
};
export type LoginSuccessResponse = {
    error: null;
    data: {
        session: Session;
        method: "password" | "totp"; // SupportedOidcProvider is done via redirect so not exposed on this type
        tenant?: string;
        sessionId: string;
        userId: string;
        email: string;
    };
};
type OidcRedirectSuccess = {
    error: null;
    data: {
        redirect: string;
    };
};

const expectedLoginErrors = [
    "session_already_available",
    "aal2_required",
    "unknown_error",
    "invalid_credentials",
    "network_error",
    "no_workspaces_found",
    "not_verified",
    "account_inactive",
    "sso_login_required",
];
type LoginError = OryError<(typeof expectedLoginErrors)[number]>;

export async function passwordLogin(
    email: string,
    password: string,
    opts?: LoginOpts
): Promise<LoginSuccessResponse | LoginError> {
    const res = await initLoginFlow(opts);
    if (res.error != null) {
        return res;
    }
    const { csrf, flow } = res.data;
    try {
        const res = await oryClient.updateLoginFlow({
            flow,
            updateLoginFlowBody: {
                identifier: email,
                csrf_token: csrf,
                method: "password",
                password,
                password_identifier: email,
            },
        });
        if (res.data.session.identity == null) {
            return { error: { code: "aal2_required" }, data: null };
        }
        const { session } = res.data;
        return {
            error: null,
            data: {
                session,
                method: "password",
                tenant: session.identity.traits.tenant,
                sessionId: session.id,
                userId: session.identity.id,
                email: session.identity.traits.email,
            },
        };
    } catch (e) {
        if (axios.isAxiosError(e)) {
            if (!e.response) {
                return { error: { code: "network_error" }, data: null };
            }
            if (e.response.status === 400) {
                const err = e as AxiosError<LoginErrorResponse>;
                return extractErrorFromUi(err, expectedLoginErrors);
            } else if (e.response.data.error.message === "identity is disabled") {
                return { error: { code: "account_inactive" }, data: null };
            }
        }
    }
    return errUnknown;
}
const totpLoginErrorCodes = [
    "network_error",
    "unknown_error",
    "session_already_available",
    "invalid_authentication_code",
    "account_inactive",
] as const;
type TotpLoginError = OryError<(typeof totpLoginErrorCodes)[number]>;
export async function totpLogin(
    code: string,
    opts?: Exclude<LoginOpts, "aal">
): Promise<LoginSuccessResponse | TotpLoginError> {
    const res = await initLoginFlow({ ...opts, aal: "aal2" });
    if (res.error != null) {
        return res;
    }
    const { csrf, flow } = res.data;
    try {
        const lg = await oryClient.updateLoginFlow({
            flow,
            updateLoginFlowBody: {
                csrf_token: csrf,
                method: "totp",
                totp_code: code,
            },
        });
        const { session } = lg.data;
        return {
            error: null,
            data: {
                session,
                method: "totp",
                tenant: session.identity.traits.tenant,
                sessionId: session.id,
                userId: session.identity.id,
                email: session.identity.traits.email,
            },
        };
    } catch (e) {
        if (axios.isAxiosError(e)) {
            if (!e.response) {
                return { error: { code: "network_error" }, data: null };
            }
            if (e.response.status === 400) {
                const err = e as AxiosError<LoginErrorResponse>;
                return extractErrorFromUi(err, totpLoginErrorCodes);
            } else if (e.response.data.error.message === "identity is disabled") {
                return { error: { code: "account_inactive" }, data: null };
            }
        }
        return errUnknown;
    }
}

export async function oidcLogin(
    email: string | null,
    provider: SSOProvider,
    domain?: string,
    opts?: LoginOpts
): Promise<OidcRedirectSuccess | OryError> {
    const res = await initLoginFlow(opts);
    if (res.error != null) {
        return res;
    }
    const { csrf, flow } = res.data;

    const upstream_parameters: UpstreamParameters = {};
    if (email && provider === SSOProviderMicrosoft) {
        upstream_parameters.login_hint = email;
    }
    if (domain && provider === SSOProviderMicrosoft) {
        upstream_parameters.domain_hint = domain;
    }

    try {
        await oryClient.updateLoginFlow({
            flow,
            updateLoginFlowBody: {
                csrf_token: csrf,
                method: "oidc",
                provider: provider.id,
                upstream_parameters,
            },
        });
        // we should never get here
        return errUnknown;
    } catch (e) {
        if (!axios.isAxiosError(e)) {
            return errUnknown;
        }
        if (e.response?.status == 422) {
            const err = e as AxiosError<BrowserLocationChangeRequired>;
            const href = err.response?.data.redirect_browser_to;
            if (href) {
                return { error: null, data: { redirect: href } };
            }
        } else if (e.response?.status == 400) {
            const err = e as AxiosError<LoginErrorResponse>;
            const issues = collectErrors(err);
            if (issues.length > 0) {
                return issues[0];
            } else if (e.response.data.error.message === "identity is disabled") {
                return { error: { code: "account_inactive" }, data: null };
            }
        }
        return errUnknown;
    }
}
