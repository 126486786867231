import { defineMessages } from "react-intl";

const messages = defineMessages({
    0: {
        id: "configuring-page.0",
        defaultMessage:
            "Configuring the stystem, this might take up to 10 minutes ⌛ In the mean time, go grab yourself a coffe ☕",
    },
    1: {
        id: "configuring-page.1",
        defaultMessage:
            "Fun fact: The word 'contract' comes from the Latin 'contractus', which means 'drawn together'. We're excited to help you draw together all your contracts in one place! 📃",
    },
    2: {
        id: "configuring-page.2",
        defaultMessage:
            "What do Mozart, Beethoven, and your new contract management system have in common? They all require skillful composition! 🎼",
    },
    3: {
        id: "configuring-page.3",
        defaultMessage:
            "🌍 Did you know? The oldest known written contract dates back to 2025 BC, found in Mesopotamia. Fast forward to today, and we're excited to bring you the latest in contract management technology! ⌛",
    },
    4: {
        id: "configuring-page.4",
        defaultMessage:
            "🏆 As we set up your new system, let's take a moment to appreciate the power of contracts. They've been used to establish peace treaties, business deals, and even the foundations of nations. Here's to making your contracts just as powerful! 💪",
    },
    5: {
        id: "configuring-page.5",
        defaultMessage:
            "📚 While you wait, here's a literary connection: Shakespeare's play 'The Merchant of Venice' revolves around a contract that demands a pound of flesh as collateral. Don't worry, our contract management system is much more user-friendly and far less dramatic! 🎭",
    },
    6: {
        id: "configuring-page.6",
        defaultMessage:
            "🎨 As we're setting up your contract management system, take a moment to appreciate the art of negotiation. Just like a painter carefully selecting colors, a great negotiator knows the importance of finding the perfect balance. 🖌",
    },
    7: {
        id: "configuring-page.7",
        defaultMessage:
            "🌟 Fun fact: The world's most expensive contract was the Louisiana Purchase in 1803, where the US bought land from France for $15 million. While our contract management system can't handle deals quite that big, we're confident it'll streamline your daily operations! 💰",
    },
    8: {
        id: "configuring-page.8",
        defaultMessage:
            "🏁 We're almost done setting up your new contract management system! Thank you for your patience. Remember, 'good things come to those who wait'. In this case, a state-of-the-art solution to manage your contracts awaits you! 🌟",
    },
    9: {
        id: "configuring-page.9",
        defaultMessage:
            "Did you know that the average person spends nearly 5 years of their life waiting in lines and queues? Thankfully, you're not in a physical line right now! 🚶‍♂️🚶‍♀️",
    },
});

export default messages;
