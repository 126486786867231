import { defineMessages } from "react-intl";

const messages = defineMessages({
    registerBody: {
        defaultMessage: "Once registered you will be able to create/access your workspace",
        id: "once registered you will be able to access your workspace",
    },
    noPasswordSignupInfo: {
        defaultMessage:
            "Your workspace administrator has disabled password sign up. Please use social sign in to get access to the workspace.",
        id: "Your workspace administrator has disabled password sign up. Please use social sign in to get access to the workspace.",
    },
    or: {
        defaultMessage: "or",
        id: "or",
    },
    submit: {
        defaultMessage: "Register",
        id: "Register",
    },
    registerTitle: {
        defaultMessage: "Register new user",
        id: "Register new user",
    },
    firstName: {
        defaultMessage: "First name",
        id: "First name",
    },
    lastName: {
        defaultMessage: "Last name",
        id: "Last name",
    },
    email: {
        defaultMessage: "Email",
        id: "Email",
    },
    password: {
        defaultMessage: "Password",
        id: "Password",
    },
    confirmPassword: {
        defaultMessage: "Confirm password",
        id: "confirmPassword",
    },
    passwordMismatch: {
        defaultMessage: "Passwords do not match",
        id: "passwordMismatch",
    },
    continueWithMicrosoft: {
        id: "continueWithMicrosoft",
        defaultMessage: "Continue with Microsoft",
    },
    termsAndConditions: {
        id: "termsAndConditions",
        defaultMessage: "By registering a user you agree to our {termsLink}",
    },
    termsLink: {
        id: "termsLink",
        defaultMessage: "Terms & Conditions",
    },
    // courtsey chatGPT for parsing this for me
    "4000006": {
        id: "register.4000006",
        defaultMessage:
            "The provided credentials are invalid, check for spelling mistakes in your password or username, email address, or phone number.",
    },
    "4000007": {
        id: "register.4000007",
        defaultMessage: "An account with the same email already exists.",
    },
    "4000027": {
        id: "register.4000027",
        defaultMessage: "An account with the same email already exists.",
    },
    "4000031": {
        id: "4000031",
        defaultMessage: "The password is too similar to the email address. Please use a different password.",
    },
    "4000008": {
        id: "4000008",
        defaultMessage: "The provided authentication code is invalid, please try again.",
    },
    "4000012": {
        id: "4000012",
        defaultMessage: "This backup recovery code has already been used.",
    },
    "4000019": {
        id: "4000019",
        defaultMessage:
            "Could not find any login identifiers. Did you forget to set them? This could also be caused by a server misconfiguration.",
    },
    "4000020": {
        id: "4000020",
        defaultMessage: "Account not active yet. Did you forget to verify your email address?",
    },
    "4000021": {
        id: "4000021",
        defaultMessage: "You have no TOTP device set up.",
    },
    "4000024": {
        id: "4000024",
        defaultMessage: "You have no backup recovery codes set up.",
    },
    "4000025": {
        id: "4000025",
        defaultMessage: "You have no WebAuthn device set up.",
    },
    "4000026": {
        id: "4000026",
        defaultMessage: "This account does not exist or has no security key set up.",
    },
    "4000034": {
        id: "4000034",
        defaultMessage: "The provided password is too weak. Please choose a stronger password.",
    },
    genericError: {
        id: "register.genericError",
        defaultMessage: "An error occurred, please try again later.",
    },
});

export default messages;
