import { Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "./messages";
import { track } from "@ignite-analytics/track";
import { oidcLink } from "@/hooks/settings";
import * as Sentry from "@sentry/react";
import { LoadingButton } from "@mui/lab";
import getProvider from "@/utils/getProvider";
import { SSOProvider } from "@/types";

type Props = {
    provider: string;
    email: string;
};

type LoginWithSocialProviderProps = {
    method: string;
};

async function onSubmit(provider: SSOProvider) {
    track("onboarding: oidc login", { provider: provider.id });
    const { error, data } = await oidcLink(provider);
    if (error) {
        Sentry.captureMessage("onboarding: failed to link", {});
        return;
    }
    window.location.href = data.redirect;
}

export const LoginWithSocialProvider = ({ method }: LoginWithSocialProviderProps) => {
    const provider = getProvider(method);
    if (!provider) {
        Sentry.captureException(new Error(`unknown provider ${method}`));
        throw new Error(`unknown provider ${JSON.stringify(method)}`);
    }

    return (
        <Stack alignItems="center">
            <LoadingButton
                type="submit"
                sx={{
                    width: "350px",
                }}
                onClick={() => onSubmit(provider)}
                startIcon={<img width={24} src={provider.iconSrc} />}
                variant="outlined"
                color="secondary"
                size="large"
                loading={false}
            >
                <span>{provider.name}</span>
            </LoadingButton>
        </Stack>
    );
};

export const OnboardingWithOidc = ({ provider, email }: Props) => {
    const { formatMessage: fm } = useIntl();
    return (
        <Stack alignItems="center" gap={2}>
            <Typography>{fm(messages.ssoDescription)}</Typography>
            <Typography>{fm(messages.ssoDescription2, { email })}</Typography>
            <LoginWithSocialProvider method={provider} />
        </Stack>
    );
};
