import { Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "./messages";
type Props = {
    invitedBy: {
        display: string;
    };
    workspace: {
        display: string;
    };
};
export const Header = ({ invitedBy, workspace }: Props) => {
    const { formatMessage: fm } = useIntl();
    return (
        <Stack alignItems="center" sx={{ mx: "auto" }} gap={2} mb={4}>
            <Typography variant="h3" align="center">
                {fm(messages.title, { name: workspace.display, inviter: invitedBy.display })}
            </Typography>
            <Typography variant="h5" align="center">
                {fm(messages.subtitle)}
            </Typography>
        </Stack>
    );
};
