import { defineMessages } from "react-intl";

const messages = defineMessages({
    or: {
        defaultMessage: "or",
        id: "or",
    },
    socialSigninRequired: {
        id: "socialSigninRequired",
        defaultMessage: "You must sign in with a social provider",
    },
    genericError: {
        id: "loginForm.genericError",
        defaultMessage: "Something went wrong",
    },
    userNotFound: {
        id: "userNotFound",
        defaultMessage: "User not found",
    },
    continueWith: {
        id: "continueWith",
        defaultMessage: "Continue with {provider}",
    },
    email: {
        id: "email",
        defaultMessage: "Email",
    },
    labelPassword: {
        id: "labelPassword",
        defaultMessage: "Password",
    },
    labelMFA: {
        id: "labelMFA",
        defaultMessage: "Enter 6 digit code",
    },
    continue: {
        id: "continue",
        defaultMessage: "Continue",
    },
    verify: {
        id: "verify",
        defaultMessage: "Verify",
    },
    invalidCredentials: {
        id: "invalidCredentials",
        defaultMessage: "Invalid credentials",
    },
    ["identity is disabled"]: {
        id: "identity is disabled",
        defaultMessage:
            "This user has been disabled. Please contact support for assistance if you believe this is an error.",
    },
    [4000006]: {
        id: "loginForm.4000006",
        defaultMessage: "Invalid credentials",
    },
    invalid_authentication_code: {
        id: "invalid_authentication_code",
        defaultMessage: "Invalid authentication code",
    },
    session_already_available: {
        id: "session_already_available",
        defaultMessage: "You are already logged in",
    },
    invalid_credentials: {
        id: "invalid_credentials",
        defaultMessage: "Username or password is incorrect",
    },
    sso_login_required: {
        id: "sso_login_required",
        defaultMessage: "You tried to sign in with password, but a workspace you belong to requires social sign in. Please contact your administrator for assistance.",
    },
    user_not_found: {
        id: "user_not_found",
        defaultMessage: "No user found with this email",
    },
    not_verified: {
        id: "login.not_verified",
        defaultMessage:
            "You have not verified your email address yet. Please check your email for a verification link.",
    },
    internal_server_error: {
        id: "internal_server_error",
        defaultMessage: "Something went wrong on our end. Please wait a few minutes and try again.",
    },
    sso_required: {
        id: "sso_required",
        defaultMessage: "You must sign in with a social provider",
    },
    no_login_methods: {
        id: "no_login_methods",
        defaultMessage: "No login methods available. If you believe this is an error, please contact support.",
    },
    forgotPassword: {
        id: "forgotPassword",
        defaultMessage: "Forgot password?",
    },
    signIn: {
        id: "signIn",
        defaultMessage: "Sign in",
    },
    account_inactive: {
        id: "account_inactive",
        defaultMessage: "This account has been disabled. Please contact your administrator for assistance.",
    },
    unknownProvider: {
        id: "unknownProvider",
        defaultMessage: "Unknown SSO provider",
    },
});

export default messages;
