import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useIntl } from "react-intl";

import { Settings400Error, SettingsBrowserLocationChangeRequired, KratosResponseErrorGeneric } from "@/types";
import { useSession } from "@/hooks/useSession";

export interface AuthenticationError {
    message: string;
    id?: string | number;
    key?: string;
}

export function collectErrorMessages(err: Settings400Error): { id: number; text: string }[] {
    const nodeErrorCodes = err.response.data.ui.nodes.reduce(
        (acc, node) => [...acc, ...node.messages.map((m) => ({ id: m.id, text: m.text }))],
        [] as { id: number; text: string }[]
    );
    const messages = err.response.data.ui.messages?.map((m) => ({ id: m.id, text: m.text })) || [];
    return [...nodeErrorCodes, ...messages];
}

export function useHandleKratosErrorResponse(messages: Record<string, { defaultMessage: string; id: string }>) {
    // TODO: messages files
    const { formatMessage } = useIntl();
    const [errors, setErrors] = useState<AuthenticationError[]>([]);
    const session = useSession();

    const [key, setKey] = useState(1);
    function createKey() {
        const rv = key;
        setKey(key + 1);
        return rv.toString();
    }

    // returns true if the error is NOT fatal and false if it's critical and whatever operation was being performed should be aborted
    function errorHandler(err: KratosResponseErrorGeneric | Settings400Error | SettingsBrowserLocationChangeRequired): {
        errors?: AuthenticationError[];
    } {
        const { status } = err.response;

        if (status == 422) {
            err = err as SettingsBrowserLocationChangeRequired;
            window.location.href = err.response.data.redirect_browser_to;
            return {};
        } else if (status == 400) {
            const issues = collectErrorMessages(err as Settings400Error);
            const errorMessages = issues.map(({ id, text }) => {
                const message = messages[id] ? formatMessage(messages[id]) : text;
                return { message, id, key: createKey() };
            });
            setErrors([...errors, ...errorMessages]);
            return { errors: errorMessages };
        } else {
            err = err as KratosResponseErrorGeneric;
            const { error } = err.response.data;
            Sentry.captureException(error, {
                user: { id: session?.data?.session?.identity.id },
                tags: { errorId: error.id },
                extra: { error },
            });
            const messageObject = messages[error.message];
            const message = messageObject ? formatMessage(messageObject) : error.message;
            const newError = { message, id: error.id, key: createKey() };
            setErrors([...errors, newError]);
            return { errors: [newError] };
        }
    }

    function clearError(key: string) {
        setErrors(errors.filter((e) => e.key != key));
    }

    return {
        errorHandler,
        errors: errors.map((e) => ({ ...e, clear: () => e.key && clearError(e.key) })),
    };
}
