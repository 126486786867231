import { gql } from "@apollo/client";

export const createCustomerMutation = gql`
    mutation createCustomer($input: CreateCustomerInput!) {
        createCustomer(input: $input) {
            result {
                identifier
            }
        }
    }
`;

export const addCustomerMetaMutation = gql`
    mutation addCustomerMeta($input: AddCustomerMetaInput!) {
        addCustomerMeta(input: $input) {
            key
        }
    }
`;
