import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import EmailSVG from "../../assets/email.svg";

import messages from "./messages";

interface Props {
    redirectUrl?: string;
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    closeButton?: boolean;
}
const EmailVerificationModal = ({ title, description, redirectUrl, closeButton }: Props) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const login = () => {
        if (!redirectUrl || redirectUrl === "") {
            navigate({ pathname: "/" });
        } else {
            window.location.replace(redirectUrl);
        }
    };
    const [open, setOpen] = React.useState(true);

    return (
        <Dialog open={open}>
            <img src={EmailSVG} alt="email verification background" width="100%" />
            <DialogContent>
                <Stack p={3} spacing={2}>
                    <Stack direction="row">
                        <Typography variant="h4"> {title} </Typography>
                    </Stack>
                    <Typography>{description}</Typography>
                </Stack>
            </DialogContent>
            {redirectUrl != undefined && (
                <DialogActions>
                    <Button onClick={() => login()}>{formatMessage(messages.check)}</Button>
                </DialogActions>
            )}
            {closeButton && (
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{formatMessage(messages.close)}</Button>
                </DialogActions>
            )}
        </Dialog>
    );
};
export default EmailVerificationModal;
