import localeDataDeDE from "./de-DE.json";
import localeDataEnUS from "./en-US.json";
import localeDataNbNO from "./nb-NO.json";

export const getMessages = (locale: string) => {
    if (locale === "nb-NO") return localeDataNbNO;
    if (locale === "de-DE") return localeDataDeDE;
    return localeDataEnUS;
};

