export const INDUSTRY_KEYS = [
    "AccommodationandFoodServices",
    "AdministrationBusinessSupportandWasteManagementServices",
    "AgricultureForestryFishingandHunting",
    "ArtsEntertainmentandRecreation",
    "Construction",
    "EducationalServices",
    "FinanceandInsurance",
    "HealthcareandSocialAssistance",
    "Information",
    "Manufacturing",
    "Mining",
    "ProfessionalScientificandTechnicalServices",
    "RealEstateandRentalandLeasing",
    "RetailTrade",
    "TransportationandWarehousing",
    "Utilities",
    "WholesaleTrade",
    "Other",
];
