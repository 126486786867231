import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "verificationModal.title",
        defaultMessage: "Welcome to Ignite!",
    },
    subTitle: {
        id: "verificationModal.subTitle",
        defaultMessage: "A verification email has been sent to your email: {email}",
    },
    description: {
        id: "verificationModal.description",
        defaultMessage:
            "Before you can sign in you need to confirm that we have registered the right email address for your account. If you are unable to locate the email in your inbox please check your spam folder. You may close this window.",
    },
});

export default messages;
