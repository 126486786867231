import { Background } from "@/components/Background";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { getDesignTokens } from "@ignite-analytics/theme";
import { ThemeProvider, createTheme } from "@mui/material";
import { IntlProvider } from "react-intl";
import { Outlet } from "react-router-dom";

import { SnackMessageContextProvider } from "@/contexts/SnackMessageContext";
import { useEffect } from "react";
import { getMessages } from "./lib/i18n/compiled";
import { getCurrentDomain } from "./utils/getCurrentHostname";
import { useNewAuthDesign } from "./utils/useNewIgnite";

const apolloClient = new ApolloClient({
    uri: import.meta.env.VITE_GRAPHQL_GATEWAY_URL || "",
    credentials: "include",
    cache: new InMemoryCache(),
});

function App() {
    // TODO: If you want to enable riddle, replace line 19 with 20
    // const designTokens = getDesignTokens("ignite-riddle", "light");
    const newDesign = useNewAuthDesign();
    const designTokens = getDesignTokens(newDesign ? "ignite-riddle" : "bigbang", "light");
    const locale = localStorage.getItem("locale") || "en-US";

    const isIgniteNoAndNoLocalStorageEntry = getCurrentDomain() === "ignite" && !localStorage.getItem("ignite.no");
    useEffect(() => {
        // While migrating to the new domain, we redirect any users that do not have ignite.no in localStorage to the old domain
        if (isIgniteNoAndNoLocalStorageEntry) {
            window.location.assign("https://auth.igniteprocurement.com");
        }
    }, [isIgniteNoAndNoLocalStorageEntry]);

    return (
        <ApolloProvider client={apolloClient}>
            <IntlProvider locale={locale} messages={getMessages(locale)} key={locale} defaultLocale="en-US">
                <ThemeProvider theme={createTheme(designTokens)}>
                    <SnackMessageContextProvider>
                        {!newDesign && <Background />}
                        <Outlet />
                    </SnackMessageContextProvider>
                </ThemeProvider>
            </IntlProvider>
        </ApolloProvider>
    );
}

export default App;
