import React from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import messages from "../messages";
import { VisibilityTextField } from "@/utils/passwordfield";

type Props = {
    onSubmit: (pw: string) => Promise<void>;
    onChange?: (pw: string) => void;
    loading: boolean;
    disabled: boolean;
};

export const UpdatePassword = ({ onSubmit, onChange, loading, disabled }: Props) => {
    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setButtonDisabled(true);
        const fd = new FormData(e.currentTarget);
        const newPassword = fd.get("password") as string;
        e.currentTarget.password.value = "";
        onSubmit(newPassword);
    }
    const [buttonDisabled, setButtonDisabled] = React.useState(true);
    function handleChange(pw: string) {
        setButtonDisabled(pw.length < 8 || disabled);
        onChange?.(pw);
    }
    const { formatMessage } = useIntl();
    return (
        <Stack rowGap={2} component="form" onSubmit={handleSubmit}>
            <VisibilityTextField
                onChange={handleChange}
                name="password"
                label="New password"
                placeholder="Password"
                required
                disabled={disabled}
            />
            <Stack direction="row" justifyContent="flex-end">
                <LoadingButton disabled={buttonDisabled} variant="contained" loading={loading} type="submit">
                    <span>{formatMessage(messages.updatePasswordButton)}</span>
                </LoadingButton>
            </Stack>
        </Stack>
    );
};
