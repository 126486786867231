import React from 'react'

export const Background: React.FC = () => {
    return (
        <div>
              <img className="squiggle"></img>
              <img className="glider"></img>
        </div>
    );
}

export default Background;
