import { Snackbar } from "@mui/material";
import React, { createContext, useContext } from "react";

export interface SnackMessageContextInterface {
    onMessage: (message: string | null) => void;
}

const SnackMessageContext = createContext<SnackMessageContextInterface | null>(null);

export type SnackMessageContextProviderProps = {
    children: React.ReactNode;
};

export function SnackMessageContextProvider({ children }: SnackMessageContextProviderProps) {
    const [snack, setSnack] = React.useState({ text: "", open: false });

    function onMessage(message: string | null) {
        if (message) {
            setSnack({ text: message || "", open: true });
            setTimeout(() => setSnack({ text: message, open: false }), 10000);
        }
    }

    return (
        <SnackMessageContext.Provider value={{ onMessage }}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snack.open}
                autoHideDuration={6000}
                message={snack.text}
            />
        </SnackMessageContext.Provider>
    );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useSnackMessageContext() {
    const context = useContext(SnackMessageContext);
    if (!context) {
        throw new Error("useSnackMessageContext hook must be used within a BasePathProvider");
    }
    return context;
}
