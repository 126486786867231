// this page gets rendered if there's any error related to a ui flow
// see https://www.ory.sh/docs/kratos/self-service/flows/user-facing-errors
import { Button, CircularProgress, Grid, Typography, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { useErrorData, FlowType } from "./hooks";
import messages from "./messages";
import { useSession } from "@/hooks/useSession";

interface Props {
    flowType: FlowType;
}

const ErrorPage: React.FC<Props> = ({ flowType }) => {
    const [query] = useSearchParams();
    const navigate = useNavigate();
    const sess = useSession();
    const { formatMessage } = useIntl();

    const id = query.get("id") || query.get("flow");
    const { data, error } = useErrorData(flowType, id);

    const actionButton = useMemo(() => {
        if (sess.data) {
            return <Button onClick={() => navigate("/settings")}>{formatMessage(messages.btnSettings)}</Button>;
        } else {
            return <Button onClick={() => navigate("/login")}>{formatMessage(messages.btnLogin)}</Button>;
        }
    }, [sess, navigate, formatMessage]);

    const sessionExpired = useMemo(() => {
        if (!data) {
            return false;
        }
        if (data.id === "session_expired") {
            return true;
        }
        if (data.message == "no resumable session found") {
            return true;
        }
        if (data.id == "session_inactive") {
            return true;
        }
        if (data.reason == "No valid session credentials found in the request.") {
            return true;
        }
        if (data.code?.toString() == "401" || data.code?.toString() == "403") {
            return true;
        }
        return false;
    }, [data]);

    const errorText = useMemo(() => {
        if (!data) return "";
        if (typeof data.message === "string") {
            return data.message;
        } else {
            return formatMessage(data.message);
        }
    }, [data, formatMessage]);

    useEffect(() => {
        if (data && !sessionExpired && data.reportToSentry != false) {
            // we'll just ignore session expired errors, as they're not errors we have to deal with
            const text = typeof data.message === "string" ? data.message : data.message.defaultMessage;
            // any issues we get will be sent to sentry if they're not explicitly ignored
            const sentryCtx = {
                user: {
                    email: localStorage.getItem("lastEmail") ?? undefined,
                },
                tags: {
                    errorId: id,
                    code: data.code,
                    flowType: flowType,
                    message: text,
                    reason: data.reason,
                },
                extra: { ...data },
            };
            // unconditionally report to sentry so we have a good overview of all errors,
            // and possibly report as separate issues for certain error codes.
            Sentry.captureMessage("entered auth error page", sentryCtx);

            const matches5xx = data.code?.toString().match(/^5\d{2}$/);
            if (data.code === "400" || matches5xx) {
                // create a separate issue for each (code,message,reason) combination
                Sentry.captureMessage(`auth error page: ${data.code} - ${data.message} - ${data.reason}`, sentryCtx);
            }
        }
    }, [data, flowType, sessionExpired, id, sess?.data?.session?.identity.id]);

    if (!id) {
        return <Navigate to="/login" />;
    }

    if (error) {
        Sentry.captureMessage("unable to fetch error details", {
            user: { id: sess.data?.session?.identity.id },
            tags: { error },
        });
        return (
            <Grid
                container
                rowGap={4}
                justifyContent="center"
                direction="column"
                alignItems="center"
                sx={{ minHeight: "80vh" }}
            >
                <Typography variant="h4">{formatMessage(messages.unknownErrorTitle)}</Typography>
                <code>{"unable_to_fetch_error_details"}</code>
                {actionButton}
            </Grid>
        );
    }

    if (!data) {
        return (
            <Grid container justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Grid>
        );
    }

    if (sessionExpired) {
        Sentry.captureMessage("session expired: redirect to login", {
            level: "info",
            user: { id: sess?.data?.session?.identity.id },
            tags: {
                errorId: id,
                code: data.code,
                flowType: flowType,
                message: JSON.stringify(data.message),
                reason: data.reason,
            },
        });
        return <Navigate to="/login?sessionExpired=true" />;
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "80vh" /* leave some space for navbar*/ }}
        >
            <Stack rowGap={4} sx={{ textAlign: "center" }} alignItems="center">
                <Typography variant="h4">{formatMessage(messages.titleErrorGeneric)}</Typography>
                <Stack rowGap={2}>
                    <Typography variant="body1">{errorText}</Typography>
                    {data.reason && <Typography variant="body2">{data?.reason}</Typography>}
                </Stack>
                <code>{id}</code>
                {actionButton}
            </Stack>
        </Grid>
    );
};

export default ErrorPage;
