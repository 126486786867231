import { defineMessages } from "react-intl";

const messages = defineMessages({
    waitOneMinuteError: {
        defaultMessage: "At least 1 minute must pass between resending emails.",
        id: "waitOneMinuteError",
    },
    mailTitle: {
        defaultMessage: "Check your mail!",
        id: "mailTitle",
    },
    mailDescription: {
        defaultMessage:
            "An email has been sent to {email}. Please click the link in the email to continue. You may close this window",
        id: "mailDescription",
    },
    emailSentFailure: {
        defaultMessage: "failed to send recovery email. Our team has been notified",
        id: "emailSentFailure",
    },
    details: {
        defaultMessage:
            "To use Social Sign In, you must connect your account to microsoft. Receive a one-time link on e-mail and then link your account from the settings page.",
        id: "details",
    },
    emailSentFail: {
        defaultMessage: "Failed to send email. Our team has been notified.",
        id: "emailSentFail",
    },
    button: {
        defaultMessage: "Continue",
        id: "button",
    },
    resend: {
        defaultMessage: "Resend email",
        id: "resend",
    },
    linkTitle1: {
        defaultMessage: "It seems like your account is not linked to your Microsoft account",
        id: "linkTitle1",
    },
});

export default messages;
