import { SupportedLanguages } from "@/types";
import { RequestHandler, graphql } from "msw";

type Settings = {
    language: SupportedLanguages;
}
const mockSettings: Settings = {
    language: "en-US",
}

export const handlers: RequestHandler[] = [
    graphql.query("getLinkedProviders", (_, res, ctx) => {
        return res(
            ctx.data({
                getLinkedProviders: {
                    providers: ["microsoft"]
                }
            })
        )
    }),
    graphql.mutation("updateSettings", (req, res, ctx) => {
        const { language } = req.variables;
        mockSettings.language = language
        return res(
            ctx.data({
                updateSettings: {
                    ...mockSettings,
                }
            })
        );
    })
]
