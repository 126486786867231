import { Button, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Layout } from "./Layout";

type ErrorContentProps = {
    title: string;
    subtitle: string;
    redirectToApp: boolean;
};
export const ErrorContent = ({ title, subtitle, redirectToApp }: ErrorContentProps) => {
    const { formatMessage: fm } = useIntl();
    return (
        <Layout>
            <Stack pt={4} spacing={2} sx={{ textAlign: "center" }}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="body1">{subtitle}</Typography>
                {redirectToApp && (
                    <Stack py={12} direction="row" justifyContent="center">
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            href={import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL}
                        >
                            {fm(messages.toApp)}
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Layout>
    );
};
