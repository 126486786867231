import Layout from "@/components/Layout";
import { createTheme, Stack, Typography, ThemeProvider, Button, Card } from "@mui/material";
import { getDesignTokens } from "@ignite-analytics/theme";
import { getSession } from "@/hooks/useSession";

import React, { useEffect, useState } from "react";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import * as Sentry from "@sentry/react";

const ErrorElement: React.FC = () => {
    const error = useRouteError();
    const [reported, setReported] = useState(false);

    let message: string;
    let subMessage: string | undefined;
    const isRouteError = isRouteErrorResponse(error);
    if (isRouteError && error.status === 404) {
        message = "Page not found";
        !reported && setReported(true); // Don't report 404s
    } else {
        message = "Woops, an unknown error occurred";
        subMessage = "Our developers have been notified";
    }

    const backToLogin = "Back to login";
    useEffect(() => {
        if (reported) {
            return;
        }

        async function reportToSentry() {
            setReported(true);
            const session = await getSession();
            Sentry.captureException(error, {
                tags: {
                    app: "auth-frontend",
                    url: window.location.href,
                    user: session?.identity?.id,
                },
            });
        }
        reportToSentry();
    }, [reported, error, isRouteError]);

    return (
        <ThemeProvider theme={createTheme(getDesignTokens("bigbang", "light"))}>
            <Layout>
                <Card sx={{ boxShadow: 4, px: 9, py: 6, pt: 8 }}>
                    <Stack alignItems="stretch" gap={3} maxWidth={500}>
                        <Typography variant="h4" align="center">
                            {message}
                        </Typography>
                        <Typography variant="body1" align="center">
                            {subMessage}
                        </Typography>
                        <Button
                            onClick={() => {
                                window.location.href = "/";
                            }}
                            variant="contained"
                            color="primary"
                        >
                            {backToLogin}
                        </Button>
                    </Stack>
                </Card>
            </Layout>
        </ThemeProvider>
    );
};

export default ErrorElement;
