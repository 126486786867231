import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
      id: "ssoSignup.title",
      defaultMessage: "Sign Up to Ignite",
    },
    description: {
      id: "ssoSignUp.description",
      defaultMessage: "Click the link below to sign up using your third party identity provider.",
    },
    continueWith: {
        id: "continueWith",
        defaultMessage: "Continue with {provider}",
    },
    termsLink: {
        id: "termsLink",
        defaultMessage: "Terms & Conditions",
    },
    termsAndConditions: {
        id: "termsAndConditions",
        defaultMessage: "By registering a user you agree to our {termsLink}",
    },
    invalidProvider: {
        id: "invalidProvider",
        defaultMessage: "Invalid SSO provider: {provider}",
    },
});

export default messages;
