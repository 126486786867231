import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { useEffect, useMemo } from "react";

import { getSession, oryClient, useSession } from "@/hooks/useSession";

import { OryError, extractErrorFromUi, extractGenericError } from "@/hooks/login";
import { SSOProvider } from "@/types";
import { SSOProviderMicrosoft } from "@/consts";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { ErrorBrowserLocationChangeRequired, ErrorGeneric, SettingsFlow } from "@ory/kratos-client";

export function useLinkedProviders() {
    const getLinkedProvidersQuery = gql`
        query getLinkedProviders {
            getLinkedProviders {
                providers
            }
        }
    `;
    // need to go via auth-sidecar because it's not possible to reliably get the linked providers from self-service methods
    const { error, loading, data, refetch } = useQuery<{ getLinkedProviders: { providers: string[] } }>(
        getLinkedProvidersQuery
    );
    const microsoft = useMemo(() => {
        if (loading || error || !data) {
            return null;
        }
        return data.getLinkedProviders.providers.includes("microsoft");
    }, [data, error, loading]);
    const pwcId = useMemo(() => {
        if (loading || error || !data) {
            return null;
        }
        return data.getLinkedProviders.providers.includes("pwc_id");
    }, [data, error, loading]);
    useEffect(() => {
        if (error) {
            Sentry.captureException(error);
        }
    }, [error]);
    return {
        loading,
        error,
        linked: {
            microsoft,
            pwcId,
        },
        refetch,
    };
}

export function useCredentials() {
    const query = gql`
        query getCredentialState {
            getCredentialState {
                requireSso
                passwordSet
                providers
            }
        }
    `;
    const { error, loading, data } = useQuery<{
        getCredentialState: { requireSso: boolean; passwordSet: boolean; providers: string[] };
    }>(query);
    return {
        loading,
        error,
        hasPassword: data?.getCredentialState.passwordSet,
        hasMicrosoftLogin: data?.getCredentialState.providers.includes("microsoft"),
    };
}

type UpdatePasswordSuccess = {
    error: null;
    data: null;
};
type UpdatePasswordError =
    | "session_refresh_required"
    | "network_error"
    | "session_not_found"
    | "password_too_common"
    | "unknown_error";
export async function updatePassword(password: string): Promise<UpdatePasswordSuccess | OryError<UpdatePasswordError>> {
    const sess = await getSession();
    if (!sess) {
        return { error: { code: "session_not_found" }, data: null };
    }
    const { data, error } = await settingsFlow();
    if (error) {
        return { data: null, error };
    }
    const { flow, csrf_token } = data;
    try {
        await oryClient.updateSettingsFlow({
            flow,
            updateSettingsFlowBody: { csrf_token, method: "password", password },
        });
        return { error: null, data: null };
    } catch (err) {
        if (!axios.isAxiosError(err)) {
            return { error: { code: "unknown_error", details: "unknown error" }, data: null };
        }
        const e = err as AxiosError;
        if (!e.response) {
            return { error: { code: "network_error" }, data: null };
        }
        const { status } = e.response;
        if (status == 401 || status == 403 || status == 410) {
            return extractGenericError(e as AxiosError<ErrorGeneric>, ["unknown_error"]);
        } else if (status == 400) {
            return extractErrorFromUi(e as AxiosError<SettingsFlow>, ["password_too_common"]);
        }
    }
    return {
        error: { code: "unknown_error", details: `failed to update password: ${JSON.stringify(error)}` },
        data: null,
    };
}
type UpdateNameSuccess = {
    error: null;
    data: null;
};
export async function updateName(
    first: string,
    last: string
): Promise<
    UpdateNameSuccess | OryError<"session_refresh_required" | "network_error" | "session_not_found" | "unknown_error">
> {
    const sess = await getSession();
    if (!sess) {
        return { error: { code: "session_not_found" }, data: null };
    }
    const { data, error } = await settingsFlow();
    if (error) {
        return { error, data: null };
    }
    const traits = sess.identity.traits as { email: string; name: { first: string; last: string } };
    const updated = { ...traits, name: { first, last } };

    const { flow, csrf_token } = data;
    try {
        await oryClient.updateSettingsFlow({
            flow,
            updateSettingsFlowBody: { csrf_token, method: "profile", traits: updated },
        });
        return { error: null, data: null };
    } catch (err) {
        if (!axios.isAxiosError(err)) {
            return { error: { code: "unknown_error", details: "not an axios error" }, data: null };
        }
        const e = err as AxiosError;
        if (!e.response) {
            return { error: { code: "network_error" }, data: null };
        }
        return extractGenericError(e as AxiosError<ErrorGeneric>, ["session_refresh_required", "unknown_error"]);
    }
}

type SettingsFlowData = {
    data: {
        csrf_token: string;
        flow: string;
    };
    error: null;
};
async function settingsFlow(): Promise<SettingsFlowData | OryError<"unknown_error">> {
    const { data } = await oryClient.createBrowserSettingsFlow();
    const csrfNode = data.ui.nodes.find((e) => e.group === "default");
    if (!csrfNode) {
        return { error: { code: "unknown_error", details: "csrf node not found" }, data: null };
    }
    const attributes = csrfNode?.attributes as unknown as { value: string };
    const csrfToken = attributes.value;
    const flow = data.id;
    return { error: null, data: { csrf_token: csrfToken, flow } };
}

type LinkSuccessResponse = {
    error: null;
    data: { redirect: string };
};
export async function oidcLink(
    provider: SSOProvider,
    email?: string
): Promise<
    | LinkSuccessResponse
    | OryError<"network_error" | "already_linked" | "session_not_found" | "unknown_error" | "missing_redirect">
> {
    const sess = await getSession();
    if (!sess) {
        return { error: { code: "session_not_found" }, data: null };
    }
    const { data, error } = await settingsFlow();
    if (error) {
        return { error, data: null };
    }
    const { flow, csrf_token } = data;
    try {
        let upstream_parameters: undefined | { login_hint: string };
        if (email && provider === SSOProviderMicrosoft) {
            upstream_parameters = { login_hint: email };
        }
        await oryClient.updateSettingsFlow({
            flow,
            updateSettingsFlowBody: { csrf_token, method: "profile", link: provider.id, upstream_parameters },
        });
        return {
            error: { code: "missing_redirect", details: "expected settings flow to redirect to oidc provider" },
            data: null,
        };
    } catch (err) {
        if (!axios.isAxiosError(err)) {
            return { error: { code: "unknown_error" }, data: null };
        }
        const e = err as AxiosError;
        if (!e.response) {
            return { error: { code: "network_error" }, data: null };
        }
        const { status } = e.response;
        if (status == 401 || status == 403 || status == 410) {
            return extractGenericError(e as AxiosError<ErrorGeneric>, ["unknown_error"]);
        } else if (status == 422) {
            const e2 = e as AxiosError<ErrorBrowserLocationChangeRequired>;
            const { error, redirect_browser_to } = e2.response!.data;
            if (redirect_browser_to) {
                return { error: null, data: { redirect: redirect_browser_to } };
            }
            if (error) {
                const { id, message } = error.error;
                const details = `${id}: ${message}`;
                return { error: { code: "unknown_error", details }, data: null };
            }
        } else if (status == 400) {
            return extractErrorFromUi(e as AxiosError<SettingsFlow>, ["already_linked"]);
        }
        return { error: { code: "unknown_error" }, data: null };
    }
}
type UnlinkSuccessResponse = {
    error: null;
    data: null;
};
export async function oidcUnlink(
    provider: SSOProvider
): Promise<UnlinkSuccessResponse | OryError<"network_error" | "session_not_found" | "unknown_error">> {
    const sess = await getSession();
    if (!sess) {
        return { error: { code: "session_not_found" }, data: null };
    }
    const { data, error } = await settingsFlow();
    if (error) {
        return { error, data: null };
    }
    const { flow, csrf_token } = data;
    try {
        await oryClient.updateSettingsFlow({
            flow,
            updateSettingsFlowBody: { csrf_token, method: "profile", unlink: provider.id },
        });
        return { error: null, data: null };
    } catch (err) {
        if (!axios.isAxiosError(err)) {
            return { error: { code: "unknown_error" }, data: null };
        }
        const e = err as AxiosError;
        if (!e.response) {
            return { error: { code: "network_error" }, data: null };
        }
        const { status } = e.response;
        if (status == 401 || status == 403 || status == 410) {
            return extractGenericError(e as AxiosError<ErrorGeneric>, ["unknown_error"]);
        } else if (status == 422) {
            return {
                error: { code: "unknown_error", details: "browser redirect in unlink: should not happen" },
                data: null,
            };
        } else if (status == 400) {
            return extractGenericError(e as AxiosError<ErrorGeneric>, []);
        }
        return { error: { code: "unknown_error" }, data: null };
    }
}

export function sessionExpired(redirectTo: string, extra?: Record<string, string | undefined>) {
    const baseUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_PUBLIC_IGNITE_URL : import.meta.env.VITE_PUBLIC_URL;
    const url = new URL(`${baseUrl}/session-refresh`);
    url.searchParams.set("redirect_url", redirectTo);
    if (extra) {
        Object.entries(extra).forEach(([k, v]) => {
            if (v) {
                url.searchParams.set(k, v);
            }
        });
    }
    window.location.href = url.toString();
}

export function useCheckPrivilegedSession() {
    const sess = useSession();
    const privilegedSession = useMemo(() => {
        if (!sess.data?.session?.authenticated_at) {
            return false;
        }
        const authTimeStamp = Date.parse(String(sess.data?.session?.authenticated_at));
        const timeAfterAuth = (Date.now() - authTimeStamp) / 1000 / 60;
        const KRATOS_PRIVILEGE_SESSION_TIMEOUT = 14; // 15 minutes - 1, if changed in Ory console update here.
        return timeAfterAuth < KRATOS_PRIVILEGE_SESSION_TIMEOUT;
    }, [sess.data?.session?.authenticated_at]);
    return {
        privilegedSession,
        redirect: (params: Record<string, string | undefined>) => {
            const baseUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_PUBLIC_IGNITE_URL : import.meta.env.VITE_PUBLIC_URL;
            const url = new URL(`${baseUrl}/session-refresh`);
            const ps: Record<string, string> = {};
            for (const [k, v] of Object.entries(params)) {
                if (v) {
                    ps[k] = v;
                }
            }
            const urlParams = new URLSearchParams(ps);
            url.search = urlParams.toString();
            window.location.href = url.toString();
        },
    };
}
