
export const whoAmI = {
    id: "aa312ee0-c456-48b2-a310-45179176324c",
    active: true,
    expires_at: "2023-08-27T10:28:09.12268Z",
    authenticated_at: "2023-08-20T10:28:09.12268Z",
    authenticator_assurance_level: "aal1",
    authentication_methods: [
        {
            method: "password",
            aal: "aal1",
            completed_at: "2023-08-20T10:28:09.122653274Z"
        }
    ],
    issued_at: "2023-08-20T10:28:09.12268Z",
    identity: {
        id: "b9b475d5-5b5a-4e57-b28f-84645ea0e0e0",
        schema_id: "a477822909a87f5be801e919c2e07e4d650b065bee6e858f188a2c95781f087474748cf1d030e3d669c8456273786b8f021cab77f8c06dada0c2c675cf8b84be",
        schema_url: "https://ory.igniteprocurement.com/schemas/YTQ3NzgyMjkwOWE4N2Y1YmU4MDFlOTE5YzJlMDdlNGQ2NTBiMDY1YmVlNmU4NThmMTg4YTJjOTU3ODFmMDg3NDc0NzQ4Y2YxZDAzMGUzZDY2OWM4NDU2MjczNzg2YjhmMDIxY2FiNzdmOGMwNmRhZGEwYzJjNjc1Y2Y4Yjg0YmU",
        state: "active",
        traits: {
            email: "basketball@face.com",
            name: {
                first: "Basketball",
                last: "McBasketballface"
            }
        },
        verifiable_addresses: [
            {
                id: "671a61ce-989f-45ae-af9a-74c932e6f895",
                value: "basketball@face.com",
                verified: true,
                via: "email",
                status: "completed",
                verified_at: "2023-05-15T09:00:05.369509Z",
                created_at: "2023-01-16T11:53:11.486143Z",
                updated_at: "2023-05-15T10:32:42.727636Z"
            }
        ],
        recovery_addresses: [
            {
                id: "de18cfb8-890d-47e3-bb78-ebde5ad7fdf2",
                value: "basketball@face.com",
                via: "email",
                created_at: "2023-01-16T11:53:11.491706Z",
                updated_at: "2023-01-16T11:53:11.491706Z"
            }
        ],
        metadata_public: {
            language: "en-US",
            region: "en-US",
            tenant: "igniteprocurement",
        },
        created_at: "2023-01-16T11:53:11.481947Z",
        updated_at: "2023-08-08T06:26:53.411939Z"
    },
    devices: [
        {
            id: "0c7de733-3ec4-4926-814d-dba1d208c0d2",
            ip_address: "213.52.37.155",
            user_agent: "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36",
            location: "Oslo, NO"
        }
    ]
}

