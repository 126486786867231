import PwcLogo from "@/assets/pwc-logo.svg";
import MicrosoftLogo from "@/assets/ms-logo.svg";
import { SSOProvider } from "@/types";

export const SSOProviderMicrosoft: SSOProvider = {
    id: "microsoft",
    name: "Microsoft",
    iconSrc: MicrosoftLogo,
} as const;

export const SSOProviderPwc: SSOProvider = {
    id: "vtBaMgf9",
    name: "PwCID",
    iconSrc: PwcLogo,
} as const;

export const SSOProviderGEA: SSOProvider = {
    id: "j7jqNK0_",
    name: "GEA Okta",
    iconSrc: undefined,
} as const;

